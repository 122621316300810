import {Button, Grid, Link} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {useTheme} from "styled-components";
import {keycloak} from "../..";
import Ret from "../../img/Clip path group.png";
import HugoSair from "../../img/Hugo_aceno1_mao 1.png";
import CardMenu, {MenuPhone} from "../menu";
import MenuSuperior from "../menuSuperior";
import {mobile} from "../theme";

const Sair = ({ decodedToken }: any) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={8}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid
            item
            xs={12}
            style={{
              background: theme.cor.cor_branco_100,
              padding: 0,
              margin: 0,
            }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Sair"} />
          </Grid>

          <Grid
            item
            xs={true}
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              style={{
                width: "130%",
                height: "100%",
                transform: "translateX(-12%)",
              }}
              src={Ret}
              alt="Imagem de fundo"
            />
          </Grid>

          <Grid item style={{ overflow: "hidden" }}>
            <Dialog open={open} style={{ overflow: "hidden" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                style={{
                  padding: "2rem",
                  paddingBottom: 0,
                  background: theme.cor.cor_branco_100,
                }}
              >
                <Grid minWidth={"200px"} item xs={true}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    height={"100%"}
                  >
                    <Grid item>
                      <img
                        src={HugoSair}
                        alt="Hugo Sair"
                        style={{ width: "100%", marginBottom: "-4.5px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid item>
                    <p
                      style={{
                        color: theme.cor.cor_preto_500,
                        fontWeight: theme.fonte.bold.semi_bold,
                        fontSize: "24px",
                        fontFamily: theme.fonte.font.fonte_1,
                      }}
                    >
                      Finalizando a sessão...
                    </p>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <p
                        style={{
                          color: theme.cor.cor_preto_500,
                          fontFamily: theme.fonte.font.fonte_1,
                          marginRight: "2rem",
                          textAlign: "right",
                        }}
                      >
                        Antes de sair, confira se não há mais nada a ser feito
                        na plataforma.
                      </p>
                    </Grid>
                    <Grid item style={{ marginRight: "2rem" }} xs={true}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item style={{ marginBottom: "1rem" }}>
                          <Link href="/home">
                            <Button
                              sx={{
                                "&:hover": {
                                  background: "none",
                                },
                                color: theme.cor.cor_principal_500,
                              }}
                              style={{ fontFamily: theme.fonte.font.fonte_1 }}
                              size="small"
                            >
                              Cancelar
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item style={{ marginBottom: "1rem" }}>
                          <Button
                            sx={{
                              "&:hover": {
                                background: theme.cor.cor_principal_500,
                              },
                              color: theme.cor.cor_branco_100,
                              background: theme.cor.cor_principal_500,
                            }}
                            style={{ fontFamily: theme.fonte.font.fonte_1 }}
                            variant="contained"
                            size="small"
                            onClick={(e: any) => {
                              keycloak.logout();
                            }}
                          >
                            Sair
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Sair;
