import {Container, Grid} from "@mui/material";
import {useTheme} from "styled-components";
import "./index.css";

const Carregar = () => {
  const theme = useTheme();
  return (
    <Container style={{ height: "100vh" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <span className="loader" style={{ color: theme.cor.cor_preto_300 }} />
      </Grid>
    </Container>
  );
};

export const CarregarLoading = () => {
  const theme = useTheme();
  return (
    <Container style={{ height: "50vh" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <span className="loader" style={{ color: theme.cor.cor_preto_300 }} />
      </Grid>
    </Container>
  );
};

export const Loading = () => {
  const theme = useTheme();
  return (
    <Container style={{ height: "100%", marginTop: "1rem" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <span className="loader" style={{ color: theme.cor.cor_preto_300 }} />
      </Grid>
    </Container>
  );
};

export default Carregar;
