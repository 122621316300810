import CameraIcon from "@mui/icons-material/Camera";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import {LoadingButton} from "@mui/lab";
import {Box, Button, Grid, Modal} from "@mui/material";
import clsx from "clsx";
import React, {useRef, useState} from "react";
import Webcam from "react-webcam";
import {useTheme} from "styled-components";

const CameraComponent = ({ setfotoPerfil, esperar }: any) => {
  const theme = useTheme();
  const webcamRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const capture = () => {
    const imageSrc = webcamRef?.current?.getScreenshot();

    setfotoPerfil({
      foto: imageSrc,
      nome:
        "captured-image-" +
        new Date(Date.now())
          .toLocaleString("pt-BR")
          .replaceAll("/", "-")
          .replaceAll(", ", "_")
          .replaceAll(":", "-") +
        ".jpeg",
    });
    handleClose();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            background: theme.cor.cor_branco_100,
            color: theme.cor.cor_preto_300,
            fontFamily: theme.fonte.font.fonte_1,

            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: theme.cor.cor_branco_100,
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2
            style={{
              color: theme.cor.cor_preto_300,
              fontFamily: theme.fonte.font.fonte_1,
            }}
          >
            Câmera
          </h2>
          <Grid item>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
          </Grid>
          <Button
            startIcon={<CameraIcon />}
            variant="contained"
            onClick={capture}
            style={{
              width: "100%",
              background: theme.cor.cor_principal_500,
              color: theme.cor.cor_branco_100,
              fontFamily: theme.fonte.font.fonte_1,
            }}
          >
            Capturar
          </Button>
          <Button
            startIcon={<CancelIcon />}
            color="error"
            variant="contained"
            style={{
              width: "100%",
              background: theme.cor.cor_erro_500,
              color: theme.cor.cor_branco_100,
              marginTop: ".5rem",

              fontFamily: theme.fonte.font.fonte_1,
            }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
      </Modal>

      <Grid item>
        <LoadingButton
          loading={esperar}
          loadingPosition="start"
          onClick={handleOpen}
          startIcon={<CameraAltIcon />}
          disabled={esperar}
          size="small"
          variant="contained"
          style={{
            width: "100%",
            background: theme.cor.cor_principal_500,
            color: theme.cor.cor_branco_100,
            fontFamily: theme.fonte.font.fonte_1,
          }}
        >
          Tirar foto
        </LoadingButton>
      </Grid>
    </div>
  );
};

export default CameraComponent;

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});
