import {yupResolver} from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PostAddIcon from "@mui/icons-material/PostAdd";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {Link} from "@mui/joy";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    TextField,
} from "@mui/material";
import {LocalizationProvider, PickersDay,} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTheme} from "styled-components";
import {v4 as uuidv4} from "uuid";
import * as yup from "yup";
import {validarLink} from "../../types";
import api from "../axios/axios";
import Carregar from "../carregar";
import Erro from "../erro";
import CardMenu, {MenuPhone} from "../menu";
import MenuSuperior from "../menuSuperior";
import {mobile, StyledDateCalendar} from "../theme";

const Anexo: React.FC<any> = ({ arquivoUpload, arquivo, setArquivo }: any) => {
  const theme = useTheme();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles;

      let arquivoGrande = false;

      acceptedFiles.forEach((e) => {
        if (e.size > 25 * 1024 * 1024) {
          arquivoGrande = true;
        }
      });

      if (arquivoGrande) {
        toast.error(`${file.length > 1 ? "Arquivos" : "Arquivo"} muito grande`);
        return;
      } else {
        arquivoUpload(file);
      }
    },
    [arquivoUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "*": [] },
  });

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Button
          style={{
            color: theme.cor.cor_branco_100,
            background: theme.cor.cor_principal_500,
            fontFamily: theme.fonte.font.fonte_1,
          }}
          size="small"
          variant="contained"
          endIcon={<UploadFileIcon />}
          {...getRootProps()}
        >
          Upload de Arquivo
        </Button>
      </Grid>

      <Grid item xs={true}>
        {arquivo?.length > 0 ? (
          <div
            key={arquivo?.length}
            style={{
              border: "2px dashed " + theme.cor.cor_principal_500,
              width: "100%",
              textAlign: "center",
              fontFamily: theme.fonte.font.fonte_1,
              paddingLeft: "1rem",
              paddingRight: "1rem",
              userSelect: "none",
              color: theme.cor.cor_preto_300,

              backgroundColor: isDragActive
                ? theme.cor.cor_branco_100
                : theme.cor.cor_branco_100,
            }}
          >
            {arquivo?.map((e: any, i: any) => {
              return (
                <p key={i + e?.nome}>
                  {e?.id ? (
                    <Link
                      href={`https://drive.google.com/uc?export=download&id=${e?.id}&confirm=yes`}
                    >
                      {e?.nome}
                    </Link>
                  ) : (
                    e?.nome
                  )}{" "}
                  <CloseIcon
                    style={{
                      top: ".4rem",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={(_: any) => {
                      setArquivo((antes: any) => {
                        const f = antes.filter((item: any) => {
                          if (
                            item?.id + item?.nome + item?.data ===
                            e?.id + e?.nome + e?.data
                          )
                            return false;

                          return true;
                        });
                        return f;
                      });
                    }}
                  />
                </p>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

const schemaAula = yup
  .object()
  .shape({
    nome: yup.string().required("Por favor, preencha este campo."),
    link: yup
      .string()
      .required("Por favor, preencha este campo.")
      .matches(
        /^(?:(?:https?:\/\/)|(?:www\.)|localhost|[\w.-]+(?:\.[\w\.-]+)+)(?:[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/,
        "Por favor, insira um link válido."
      ),
    pontuacao: yup
      .number()
      .typeError("A pontuação deve ser um número")
      .required("Por favor, preencha este campo.")
      .positive("A pontuação deve ser maior que zero."),
    descricao: yup.string().required("Por favor, preencha este campo."),
    tipo: yup.string().required("Por favor, preencha este campo."),
    descritor: yup.string().required("Por favor, preencha este campo."),
    id_disciplina: yup.string().required("Por favor, preencha este campo."),
    anexo: yup.mixed().nullable(),
    data_entrega: yup.string().nullable(),
    status: yup.boolean(),
    atividades: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        peso: yup
          .number()
          .nullable()
          .min(1, "O peso deve ser no mínimo 1")
          .max(100, "O peso deve ser no máximo 100")
          .transform((value, originalValue) => {
            return originalValue === "" ? null : value;
          }),
        nome: yup.string().required("Por favor, preencha este campo."),
        alternativa_certa: yup.string().nullable(),
        pergunta: yup.string().required("Por favor, preencha este campo."),
        tipo: yup.string().required("Por favor, preencha este campo."),
        alternativas: yup.array().of(
          yup.object().shape({
            nome: yup.string().required("Por favor, preencha este campo."),
            id: yup.string(),
          })
        ),
      })
    ),
  })
  .required();

const AulaItem = ({ decodedToken }: any) => {
  const theme = useTheme();
  const { uuidDisciplina, uuidAula, uuidTurma } = useParams();
  const [uuidDisciplinaItem, setuuidDisciplinaItemItem] =
    useState<any>(uuidDisciplina);
  const navigate = useNavigate();
  const [disciplinas, setDisciplinas] = useState<any>(null);
  const [arquivo, setArquivo] = useState<any>([]);
  const [ativarBtn, setAtivarBtn] = useState<any>(false);
  const [publicar, setPublicar] = useState<any>(true);
  const [avaliacaoTipo, setAvaliacaoTipo] = useState<any>(null);
  const classe = window.location.href.includes("/aulas") ? "aula" : "trilha";

  const handleArquivo = async (files: FileList | null) => {
    if (!files) {
      console.error("Nenhum arquivo fornecido.");
      return;
    }

    const fileData: { nome: string; data: string }[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      const filePromise = new Promise<{ nome: string; data: string }>(
        (resolve, reject) => {
          reader.onload = (event) => {
            const dataUrl = event.target?.result;
            if (typeof dataUrl === "string") {
              resolve({ nome: file.name, data: dataUrl });
            } else {
              reject(new Error("Falha ao ler o arquivo."));
            }
          };

          reader.onerror = () => {
            reject(new Error("Erro ao ler o arquivo."));
          };

          reader.readAsDataURL(file);
        }
      );

      try {
        const result = await filePromise;
        fileData.push(result);
      } catch (error) {
        console.error("Erro ao processar o arquivo:", error);
      }
    }

    setArquivo((antes: any) => {
      return [...antes, ...fileData];
    });
  };

  const id = uuidv4();
  const [configuracao, setConfiguracao] = useState<any>({
    loading: true,
    erro: false,
  });

  const [dataEntrega, setDataEntrega] = useState<any>(null);
  const [dataProgramar, setDataProgramar] = useState<any>(null);
  const [programar, setProgramar] = useState<any>(false);
  const [open, setOpen] = useState<any>(false);

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      nome: "",
      link: "",
      pontuacao: undefined,
      data_entrega: null,
      id_disciplina: uuidDisciplinaItem,
      descricao: "",
      tipo: "avaliacao",
      descritor: "",
      anexo: [],
      status: true,
      atividades: [
        {
          id: uuidv4(),
          tipo: "avaliacao",
          alternativa_certa: id,
          alternativas: [{ id: id }],
        },
      ],
    },
    resolver: yupResolver(schemaAula),
  });

  useEffect(() => {
    if (avaliacaoTipo === "avaliacao_quiz") {
      const prevAula = getValues();

      const id = uuidv4();
      let novaAtividade: any = {};
      let novoEstado = {};
      if (prevAula?.atividades?.length === 1) {
        novaAtividade = {
          id: uuidv4(),
          nome: "",
          peso: undefined,
          pergunta: "",
          tipo: "quiz",
          timestamp: null,
          alternativas: [...Array(1)].map((_: any) => ({
            id: id,
            nome: "",
            timestamp: null,
          })),
        };
        novoEstado = {
          ...prevAula,
          atividades: [...(prevAula?.atividades ?? []), novaAtividade],
        };
      } else {
        novoEstado = {
          ...prevAula,
          atividades: (prevAula?.atividades ?? []).map(
            (e: any, index: number) => {
              if (index === (getValues()?.atividades?.length as number) - 1) {
                e.tipo = "quiz";
              } else {
                e.tipo = "avaliacao";
              }
              return e;
            }
          ),
        };
      }

      reset(novoEstado);
    } else {
      {
        const prevAula = getValues();

        const novoEstado = {
          ...prevAula,
          atividades:
            prevAula?.atividades?.map((e) => {
              e.tipo = avaliacaoTipo;
              return e;
            }) ?? [],
        };
        reset(novoEstado);
      }
    }
  }, [avaliacaoTipo]);

  const inputRef = useRef([]);
  const deParaData: any = {
    Do: "D",
    "2ª": "S",
    "3ª": "T",
    "4ª": "Q",
    "5ª": "Q",
    "6ª": "S",
    Sá: "S",
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleClose = () => {
    setOpen(false);
    setDataEntrega(null);
  };

  useEffect(() => {
    if (uuidAula === undefined) {
      api
        .get("turma/disciplina/" + uuidTurma)
        .then((r) => {
          setDisciplinas(r.data);
        })
        .then((e: any) =>
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          })
        )
        .catch((e) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          });
          console.log(e);
        });
    } else if (uuidAula) {
      Promise.all([
        api.get(`aula/${uuidAula}`),
        api.get("turma/disciplina/" + uuidTurma),
      ])
        .then(([r, r2]) => {
          {
            setDisciplinas(r2.data);
            return r;
          }
        })
        .then((r: any) => {
          setDataEntrega(r?.data?.data_entrega);
          setArquivo(r.data.anexo);
          setPublicar(r.data.status);
          setuuidDisciplinaItemItem(r.data.id_disciplina);
          reset(r.data, {
            keepDirtyValues: false,
          });
        })
        .then((e: any) =>
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          })
        )
        .catch((e) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          });
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  const alterarPergunta = (event: any, id: string, genial = false) => {
    const valor = event.target.value;
    const elementoHTML = document.getElementById(id) as HTMLIFrameElement;
    if (id === "link") {
      elementoHTML.src = valor;
    } else if (elementoHTML) {
      if (genial && validarLink(valor)) {
        const novoElemento = elementoHTML.getElementsByTagName(
          "iframe"
        )[0] as HTMLIFrameElement;

        const div = elementoHTML.getElementsByTagName(
          "div"
        )[0] as HTMLIFrameElement;
        div.style.display = "none";
        novoElemento.style.display = "";
        novoElemento.src = valor;
      } else if (genial) {
        const novoElemento = elementoHTML.getElementsByTagName(
          "div"
        )[0] as HTMLIFrameElement;
        const elementoTamanho = document.getElementById(
          "pergunta-" + id.split("-")[1]
        ) as HTMLIFrameElement;

        const iframe = elementoHTML.getElementsByTagName(
          "iframe"
        )[0] as HTMLIFrameElement;
        iframe.style.display = "none";

        novoElemento.style.display = "";
        novoElemento.innerText = valor;

        novoElemento.style.maxWidth = `${elementoTamanho.offsetWidth}px`;
      } else {
        elementoHTML.innerText = valor;
      }
    }
  };

  const onSubmit = (data: any) => {
    data.anexo = arquivo;
    data.data_entrega = dataEntrega;
    let ajuste: boolean = false;
    data.tipo = avaliacaoTipo;
    let texto = "Não foi possível enviar";
    if (avaliacaoTipo === "quiz") {
      data?.atividades.forEach((atv: any) => {
        atv.tipo = "quiz";
        atv.alternativa_certa = null;
        atv.nota = null;
      });
    } else if (avaliacaoTipo === "treinamento") {
      data?.atividades.forEach((atv: any) => {
        if (atv?.alternativa_certa === "" || atv?.alternativa_certa === null) {
          ajuste = true;
          texto = "Escolha uma alternativa válida ";
        }
        atv.tipo = "treinamento";
      });
    } else if (avaliacaoTipo === "avaliacao") {
      data?.atividades.forEach((atv: any) => {
        if (atv.alternativa_certa === null || atv.alternativa_certa === "") {
          ajuste = true;
          texto = "Escolha uma alternativa válida ";
        }
        atv.tipo = "avaliacao";
      });
    } else if (avaliacaoTipo === "avaliacao_quiz") {
      data?.atividades.forEach((atv: any, index: any) => {
        atv.tipo = "avaliacao";
        if (index === (getValues()?.atividades?.length as number) - 1) {
          atv.alternativa_certa = null;
          atv.tipo = "quiz";
          atv.nota = null;
        } else if (
          atv.alternativa_certa === null ||
          atv.alternativa_certa === ""
        ) {
          ajuste = true;
          texto = "Escolha uma alternativa válida ";
        }
      });
    }

    if (ajuste) {
      toast.error(texto);
      return;
    }
    // console.log(data);
    // return;

    data.classe = classe;

    setAtivarBtn(true);
    if (data.disciplina === "") {
      setAtivarBtn(false);
      return;
    } else if (uuidAula) {
      toast.info("Editando aula");
      api
        .patch("aula/" + uuidAula, JSON.stringify(data))
        .then((e) =>
          navigate(
            `/${
              window.location.pathname.includes("trilhas") ? "trilhas" : "aulas"
            }/turma`
          )
        )
        .then((e: any) => toast.success("Aula editada"))
        .catch((e: any) => toast.error("Aula não editada"))
        .finally(() => {
          setAtivarBtn(false);
        });
    } else {
      toast.info("Criando aula");
      api
        .post("aula", data)
        .then((e) =>
          navigate(
            `/${
              window.location.pathname.includes("trilhas") ? "trilhas" : "aulas"
            }/turma`
          )
        )
        .then((e: any) => toast.success("Aula criada"))
        .catch((e: any) => toast.error("Aula não aula"))
        .finally(() => {
          setAtivarBtn(false);
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <React.Fragment>
          <Dialog
            onClose={handleClose}
            open={open}
            PaperProps={{
              sx: {
                borderRadius: "1.5rem",
                background: theme.cor.cor_branco_100,
              },
            }}
          >
            <DialogTitle
              sx={{ m: 0, p: 2 }}
              style={{
                marginBottom: "1rem",
                background: theme.cor.cor_branco_100,
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: theme.cor.cor_preto_400,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      color: theme.cor.cor_preto_300,
                      fontFamily: theme.fonte.font.fonte_1,
                      fontWeight: theme.fonte.bold.semi_bold,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    {programar ? "PROGRAMAR" : "Data de entrega"}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      color: theme.cor.cor_preto_300,
                      fontFamily: theme.fonte.font.fonte_1,
                      marginBottom: "-1rem",
                    }}
                  >
                    {programar
                      ? "Marque no calendário a data de abertura da atividade."
                      : "Marque no calendário o dia da entrega da atividade."}
                  </p>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
                dateFormats={{ monthAndYear: "MMMM, YYYY" }}
              >
                <StyledDateCalendar
                  dayOfWeekFormatter={(_day: any, weekday: any): any =>
                    `${deParaData[weekday?.format("dd")]}`
                  }
                  slots={{
                    day: (slotProps: any) => {
                      return (
                        <PickersDay
                          style={{
                            backgroundColor:
                              moment(
                                dayjs(slotProps["data-timestamp"])?.toDate()
                              )?.format("DD/MM/YYYY") ===
                              moment(dayjs(dataEntrega)?.toDate())?.format(
                                "DD/MM/YYYY"
                              )
                                ? theme.cor.cor_principal_500
                                : theme.cor.cor_preto_100,
                            color: theme.cor.cor_branco_100,
                            fontWeight: theme.fonte.bold.semi_bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            borderRadius: 10,
                          }}
                          day={slotProps?.day?.get("date")}
                          onDaySelect={function (dia: any): void {
                            if (
                              dataEntrega !== null &&
                              moment(
                                dayjs(slotProps["data-timestamp"])?.toDate()
                              )?.format("DD/MM/YYYY") ===
                                moment(dayjs(dataEntrega)?.toDate())?.format(
                                  "DD/MM/YYYY"
                                )
                            ) {
                              setDataEntrega(null);
                            } else
                              setDataEntrega(
                                dayjs(slotProps["data-timestamp"]).toDate()
                              );
                          }}
                          outsideCurrentMonth={false}
                          isFirstVisibleCell={false}
                          isLastVisibleCell={false}
                        >
                          {slotProps?.day?.get("date")}
                        </PickersDay>
                      );
                    },
                  }}
                />
              </LocalizationProvider>
            </DialogContent>
            <DialogActions style={{ marginTop: "-1rem", marginBottom: "1rem" }}>
              <Button
                style={{
                  background: theme.cor.cor_erro_500,
                  color: theme.cor.cor_branco_200,
                  fontFamily: theme.fonte.font.fonte_1,
                }}
                size="small"
                variant={"contained"}
                onClick={(e) => {
                  setOpen(false);
                  setDataEntrega(getValues()?.data_entrega);
                }}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  background: theme.cor.cor_verde_500,
                  color: theme.cor.cor_branco_100,
                  fontFamily: theme.fonte.font.fonte_1,
                  marginRight: "1rem",
                }}
                size="small"
                autoFocus
                onClick={(_) => {
                  setOpen(false);
                  setValue(`data_entrega`, dataEntrega);
                }}
                endIcon={<CheckCircleIcon />}
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>

        <Grid
          container
          direction="row"
          style={{ background: theme.cor.cor_fundo }}
        >
          <CardMenu
            decodedToken={decodedToken}
            menuSelecao={window.location.href.includes("/aulas") ? 3 : 20}
            ativo={mobile(windowSize)}
          />

          <Grid
            item
            xs={mobile(windowSize) ? true : false}
            width={"100%"}
            style={{ height: "100vh", overflow: "auto" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              height={"100%"}
            >
              <Grid item xs={12} marginTop={mobile(windowSize) ? "" : "4rem"}>
                <MenuPhone
                  decodedToken={decodedToken}
                  ativo={mobile(windowSize) ? true : false}
                />
                <Grid
                  item
                  xs={12}
                  style={{
                    background: theme.cor.cor_branco_100,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <div style={{ paddingTop: mobile(windowSize) ? "" : "1rem" }}>
                    <MenuSuperior
                      ativo={true}
                      texto={"Adicionar Conteúdo"}
                      setOpen={setOpen}
                      setProgramar={setProgramar}
                      uuidDisciplina={uuidDisciplinaItem}
                      ativarBtn={ativarBtn}
                      publicar={publicar}
                    />
                  </div>
                </Grid>
                <Grid item xs={true}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    padding={"1rem"}
                    spacing={2}
                  >
                    <Grid item xs={true}>
                      <Grid
                        item
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                          borderRadius: "1rem",
                          background: theme.cor.cor_branco_100,
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            background: theme.cor.cor_principal_500,
                            color: theme.cor.cor_branco_100,
                            fontFamily: theme.fonte.font.fonte_1,
                            fontSize: "18px",
                            fontWeight: theme.fonte.bold.medio,
                            padding: ".5rem",
                            borderTopLeftRadius: ".5rem",
                            borderTopRightRadius: ".5rem",
                          }}
                        >
                          <Grid item>AULA</Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              xs={12}
                              style={{
                                background: theme.cor.cor_branco_100,
                                padding: "1.5rem",
                                paddingTop: "1rem",
                                paddingBottom: "0rem",
                              }}
                            >
                              <TextField
                                fullWidth
                                size="small"
                                sx={{
                                  ".MuiOutlinedInput-input": {
                                    color: theme.cor.cor_preto_300,
                                  },
                                  "& label": {
                                    color: theme.cor.cor_preto_300,
                                  },
                                  "& label.Mui-focused": {
                                    color: theme.cor.cor_principal_500,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: theme.cor.cor_principal_500,
                                    },
                                    "& fieldset": {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                    "&:hover fieldset": {
                                      borderColor: theme.cor.cor_principal_500,
                                    },
                                  },
                                }}
                                label="Nome"
                                {...register("nome")}
                                onChange={(e) => alterarPergunta(e, `nome`)}
                                error={Boolean(formState?.errors?.nome)}
                                helperText={formState.errors.nome?.message}
                                required
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                type="text"
                              />
                            </Grid>
                            <Grid
                              item
                              marginTop={"1rem"}
                              width={"100%"}
                              style={{
                                marginLeft: "1.5rem",
                                marginRight: "1rem",
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid
                                  item
                                  xs={true}
                                  marginBottom={0}
                                  paddingBottom={0}
                                  sx={{ maxWidth: "100%" }}
                                >
                                  <FormControl
                                    fullWidth
                                    sx={{ maxWidth: "100%" }}
                                    size="small"
                                  >
                                    <InputLabel
                                      required
                                      style={{ color: theme.cor.cor_preto_500 }}
                                    >
                                      Status da Aula
                                    </InputLabel>
                                    <Select
                                      required
                                      id={`status`}
                                      defaultValue={
                                        formState?.defaultValues?.status
                                      }
                                      label="Status da Aula"
                                      style={{ color: theme.cor.cor_preto_500 }}
                                      {...register("status")}
                                      onClick={(e) => setPublicar(!publicar)}
                                      sx={{
                                        ".MuiOutlinedInput-notchedOutline": {
                                          borderColor: theme.cor.cor_preto_100,
                                        },
                                        maxWidth: "100%",
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor:
                                              theme.cor.cor_principal_500,
                                          },
                                      }}
                                    >
                                      <MenuItem value={"true"}>Aberto</MenuItem>
                                      <MenuItem value={"false"}>
                                        Fechado
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>

                                <Grid
                                  item
                                  xs={true}
                                  style={{
                                    background: theme.cor.cor_branco_100,
                                    paddingTop: "1rem",
                                    paddingBottom: "0rem",
                                    paddingRight: ".5rem",
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    size="small"
                                    sx={{
                                      ".MuiOutlinedInput-input": {
                                        color: theme.cor.cor_preto_300,
                                      },
                                      "& label": {
                                        color: theme.cor.cor_preto_300,
                                      },
                                      "& label.Mui-focused": {
                                        color: theme.cor.cor_principal_500,
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                          borderColor:
                                            theme.cor.cor_principal_500,
                                        },
                                        "& fieldset": {
                                          borderColor: theme.cor.cor_preto_100,
                                        },
                                        "&:hover fieldset": {
                                          borderColor:
                                            theme.cor.cor_principal_500,
                                        },
                                      },
                                    }}
                                    label="Pontuação"
                                    id="pontuacao"
                                    defaultValue={100}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    {...register("pontuacao")}
                                    error={Boolean(
                                      formState?.errors?.pontuacao
                                    )}
                                    helperText={
                                      formState.errors.pontuacao?.message
                                    }
                                    required
                                    type="number"
                                  ></TextField>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              style={{
                                background: theme.cor.cor_branco_100,
                                padding: "1.5rem",
                                paddingTop: "1rem",
                                paddingBottom: "0rem",
                              }}
                            >
                              <TextField
                                fullWidth
                                size="small"
                                sx={{
                                  ".MuiOutlinedInput-input": {
                                    color: theme.cor.cor_preto_300,
                                  },
                                  "& label": {
                                    color: theme.cor.cor_preto_300,
                                  },
                                  "& label.Mui-focused": {
                                    color: theme.cor.cor_principal_500,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: theme.cor.cor_principal_500,
                                    },
                                    "& fieldset": {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                    "&:hover fieldset": {
                                      borderColor: theme.cor.cor_principal_500,
                                    },
                                  },
                                }}
                                label="Descrição"
                                {...register("descricao")}
                                onChange={(e) =>
                                  alterarPergunta(e, `descricao`)
                                }
                                error={Boolean(formState?.errors?.descricao)}
                                helperText={formState.errors.descricao?.message}
                                required
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                type="text"
                              ></TextField>
                            </Grid>

                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                              style={{
                                background: theme.cor.cor_branco_100,
                                padding: "1.5rem",
                                marginLeft: "0rem",
                                paddingLeft: ".5rem",
                                paddingTop: "1rem",
                                paddingBottom: "0rem",
                                marginBottom: "0rem",
                                paddingRight: "1.5rem",
                              }}
                            >
                              <Grid
                                item
                                xs={true}
                                marginBottom={0}
                                paddingBottom={0}
                                sx={{ maxWidth: "100%" }}
                              >
                                <FormControl
                                  sx={{ maxWidth: "100%" }}
                                  fullWidth
                                  size="small"
                                >
                                  <InputLabel
                                    required
                                    style={{ color: theme.cor.cor_preto_500 }}
                                  >
                                    Tipo de Avaliação
                                  </InputLabel>
                                  <Select
                                    id={"tipo"}
                                    required
                                    key={getValues().tipo}
                                    defaultValue={() => {
                                      setAvaliacaoTipo(getValues().tipo);
                                      return (
                                        getValues().tipo && getValues().tipo
                                      );
                                    }}
                                    label="Tipo de Avaliação"
                                    style={{ color: theme.cor.cor_preto_500 }}
                                    {...register("tipo")}
                                    onChange={(e: any) => {
                                      setAvaliacaoTipo(e.target.value);
                                    }}
                                    sx={{
                                      ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      maxWidth: "100%",
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor:
                                            theme.cor.cor_principal_500,
                                        },
                                    }}
                                  >
                                    <MenuItem value={"avaliacao"}>
                                      Avaliação
                                    </MenuItem>
                                    <MenuItem value={"avaliacao_quiz"}>
                                      Avaliação + Quiz
                                    </MenuItem>
                                    <MenuItem value={"treinamento"}>
                                      Treinamento
                                    </MenuItem>
                                    <MenuItem value={"quiz"}>Quiz</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={true} sx={{ maxWidth: "100%" }}>
                                <FormControl
                                  fullWidth
                                  sx={{ maxWidth: "100%" }}
                                  size="small"
                                >
                                  <InputLabel
                                    required
                                    style={{ color: theme.cor.cor_preto_500 }}
                                  >
                                    Disciplina
                                  </InputLabel>
                                  <Select
                                    id="disciplina"
                                    defaultValue={uuidDisciplinaItem}
                                    label="Disciplina"
                                    required
                                    style={{ color: theme.cor.cor_preto_500 }}
                                    {...register("id_disciplina")}
                                    sx={{
                                      ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      maxWidth: "100%",
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor:
                                            theme.cor.cor_principal_500,
                                        },
                                    }}
                                  >
                                    {disciplinas?.items?.map((item: any) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              background: theme.cor.cor_branco_100,
                              padding: "1.5rem",
                              marginTop: "-.5rem",
                              paddingBottom: "0rem",
                            }}
                          >
                            <TextField
                              fullWidth
                              size="small"
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              label="Descritor"
                              id="descritor"
                              {...register("descritor")}
                              error={Boolean(formState?.errors?.descritor)}
                              helperText={formState.errors.descritor?.message}
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                              type="text"
                            ></TextField>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            style={{
                              padding: "1.5rem",
                              paddingTop: "0rem",
                              paddingBottom: "1rem",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: theme.fonte.font.fonte_1,
                                fontSize: "12px",
                                color: theme.cor.cor_preto_300,
                              }}
                            >
                              Conteúdo Adicional
                            </p>
                            <Anexo
                              arquivoUpload={handleArquivo}
                              arquivo={arquivo}
                              setArquivo={setArquivo}
                              key={arquivo?.length}
                            />
                            <p
                              style={{
                                fontFamily: theme.fonte.font.fonte_1,
                                fontSize: "12px",
                                color: theme.cor.cor_preto_300,
                                marginBottom: "0rem",
                              }}
                            >
                              Você pode adicionar um arquivo para reforço do
                              conteúdo da aula.
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={true}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                          background: theme.cor.cor_branco_100,
                          height: "100%",
                          borderRadius: "1rem",
                          paddingBottom: "1rem",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                          paddingTop={"1.5rem"}
                        >
                          <Grid
                            item
                            width={theme.fonte.bold.normal}
                            height={300}
                            style={{
                              marginTop: "1rem",
                              marginLeft: "1rem",
                              background: theme.cor.cor_principal_500,
                              borderRadius: "1rem",
                              boxShadow:
                                "rgba(100, 100, 111, 1) 0px 7px 10px 0px",
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="space-between"
                              alignItems="flex-start"
                              spacing={2}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  cursor: "pointer",
                                  width: "100%",
                                  height: "300px",
                                  overflow: "hidden",
                                }}
                              >
                                <iframe
                                  id="link"
                                  style={{
                                    border: 0,
                                    position: "absolute",
                                    width: "140%",
                                    height: "140%",
                                    right: -80,
                                    top: -55,
                                  }}
                                  src={
                                    formState.defaultValues &&
                                    formState.defaultValues.link
                                  }
                                ></iframe>
                                <div
                                  style={{
                                    background: "rgba(0, 0, 0, 0.7)",
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10,
                                    color: theme.cor.cor_branco_100,
                                  }}
                                >
                                  <PlayCircleOutlineIcon
                                    style={{ width: "40px", height: "40px" }}
                                  />
                                </div>
                                {/* <div
                                  style={{
                                    position: "absolute",
                                    left: 10,
                                    top: 10,
                                    background: theme.cor.cor_amarelo_500,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    padding: ".5rem",
                                    borderRadius: "1rem",
                                    fontSize: "11px",
                                  }}
                                >
                                  D{1}
                                </div> */}

                                <div
                                  style={{
                                    position: "absolute",
                                    left: 10,
                                    top: 140,
                                    fontWeight: theme.fonte.bold.semi_bold,
                                    background: theme.cor.cor_branco_100,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    color: theme.cor.cor_preto_400,
                                    padding: ".5rem",
                                    borderRadius: "1rem",
                                    fontSize: "18px",
                                    maxWidth: "321px",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <p
                                    id="nome"
                                    style={{
                                      textOverflow: "ellipsis",
                                      maxWidth: "321px",
                                      overflow: "hidden",
                                      margin: 0,
                                      padding: 0,
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                      display: "-webkit-box",
                                    }}
                                  >
                                    {formState.defaultValues?.nome &&
                                      formState.defaultValues.nome}
                                  </p>
                                </div>
                                <div>
                                  <p
                                    id="descricao"
                                    style={{
                                      textAlign: "justify",
                                      position: "absolute",
                                      left: 20,
                                      top: 180,
                                      fontFamily: theme.fonte.font.fonte_1,
                                      color: theme.cor.cor_branco_100,
                                      fontSize: "11px",
                                      marginRight: "1rem",
                                      overflowWrap: "break-word",
                                      width: "90%",
                                      maxHeight: "34%",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      WebkitLineClamp: 6,
                                      WebkitBoxOrient: "vertical",
                                      display: "-webkit-box",
                                    }}
                                  >
                                    {formState.defaultValues &&
                                    formState.defaultValues.descricao !== ""
                                      ? formState.defaultValues.descricao
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            background: theme.cor.cor_branco_100,
                            padding: "1.5rem",
                            paddingTop: "1rem",
                          }}
                        >
                          <TextField
                            fullWidth
                            size="small"
                            sx={{
                              ".MuiOutlinedInput-input": {
                                color: theme.cor.cor_preto_300,
                              },
                              "& label": {
                                color: theme.cor.cor_preto_300,
                              },
                              "& label.Mui-focused": {
                                color: theme.cor.cor_principal_500,
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: theme.cor.cor_principal_500,
                                },
                                "& fieldset": {
                                  borderColor: theme.cor.cor_preto_100,
                                },
                                "&:hover fieldset": {
                                  borderColor: theme.cor.cor_principal_500,
                                },
                              },
                            }}
                            label="Link da Aula"
                            id="link"
                            {...register("link")}
                            onChange={(e) => alterarPergunta(e, `link`)}
                            error={Boolean(formState?.errors?.link)}
                            helperText={formState.errors.link?.message}
                            required
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="text"
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {getValues()?.atividades?.map(
                      (item: any, index: number) => (
                        <Grid
                          item
                          xs={12}
                          key={`${item.id}-${item.alternativa_certa}`}
                        >
                          <Grid
                            item
                            style={{
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                              borderRadius: "1rem",
                              background: theme.cor.cor_branco_100,
                              paddingBottom: "1rem",
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              style={{
                                background: theme.cor.cor_principal_500,
                                color: theme.cor.cor_branco_100,
                                fontFamily: theme.fonte.font.fonte_1,
                                fontSize: "18px",
                                fontWeight: theme.fonte.bold.medio,
                                padding: ".5rem",
                                borderTopLeftRadius: ".5rem",
                                borderTopRightRadius: ".5rem",
                              }}
                            >
                              <Grid item></Grid>
                              <Grid item>
                                {(avaliacaoTipo === "avaliacao_quiz" &&
                                  index ===
                                    (getValues()?.atividades
                                      ?.length as number) -
                                      1) ||
                                avaliacaoTipo === "quiz"
                                  ? "Quiz"
                                  : "ATIVIDADES"}
                              </Grid>
                              <Grid item>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {avaliacaoTipo !== "avaliacao_quiz" ? (
                                    <>
                                      <Grid item>
                                        {(getValues()?.atividades
                                          ?.length as number) -
                                          1 ===
                                        index ? (
                                          <Button
                                            style={{
                                              color: theme.cor.cor_branco_100,
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              fontWeight:
                                                theme.fonte.bold.medio,
                                              background:
                                                theme.cor.cor_principal_300,
                                            }}
                                            size="small"
                                            endIcon={<PostAddIcon />}
                                            onClick={() => {
                                              const prevAula = getValues();
                                              const id = uuidv4();
                                              const novaAtividade = {
                                                id: uuidv4(),
                                                nome: "",
                                                peso: undefined,
                                                pergunta: "",
                                                tipo:
                                                  avaliacaoTipo ===
                                                  "avaliacao_quiz"
                                                    ? "avaliacao"
                                                    : avaliacaoTipo,
                                                timestamp: null,
                                                alternativa_certa: id,
                                                alternativas: [...Array(1)].map(
                                                  (_: any) => ({
                                                    id: id,
                                                    nome: "",
                                                    timestamp: null,
                                                  })
                                                ),
                                              };
                                              let novaAtiviades = [];
                                              if (
                                                avaliacaoTipo ===
                                                "avaliacao_quiz"
                                              ) {
                                                prevAula?.atividades?.splice(
                                                  prevAula?.atividades?.length -
                                                    1,
                                                  0,
                                                  novaAtividade
                                                );
                                                novaAtiviades = [
                                                  ...(prevAula?.atividades ??
                                                    []),
                                                ];
                                              } else {
                                                novaAtiviades = [
                                                  ...(prevAula?.atividades ??
                                                    []),
                                                  novaAtividade,
                                                ];
                                              }
                                              const novoEstado = {
                                                ...prevAula,
                                                atividades: novaAtiviades,
                                              };
                                              reset(novoEstado, {
                                                keepDirtyValues: false,
                                              });
                                            }}
                                          >
                                            {avaliacaoTipo === "quiz"
                                              ? "Adicionar Quiz"
                                              : "Adicionar Atividade"}
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                      {((getValues()?.atividades?.length ??
                                        0) as number) > 1 ? (
                                        <Grid item>
                                          {avaliacaoTipo === "avaliacao_quiz" &&
                                          item?.tipo === "quiz" ? (
                                            ""
                                          ) : (
                                            <Button
                                              style={{
                                                color: theme.cor.cor_branco_200,
                                                fontFamily:
                                                  theme.fonte.font.fonte_1,
                                                fontWeight:
                                                  theme.fonte.bold.medio,
                                                background:
                                                  theme.cor.cor_erro_500,
                                                padding: 0,
                                              }}
                                              size="small"
                                              onClick={() => {
                                                let aul: any = getValues();
                                                const atividades =
                                                  aul.atividades.filter(
                                                    (aula: any) =>
                                                      aula?.id !== item?.id
                                                  );

                                                const novoEstado = {
                                                  ...aul,
                                                  atividades: atividades,
                                                };
                                                reset(novoEstado, {
                                                  keepDirtyValues: false,
                                                });
                                              }}
                                            >
                                              <DeleteForeverIcon />
                                            </Button>
                                          )}
                                        </Grid>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Grid item>
                                        {(getValues()?.atividades
                                          ?.length as number) -
                                          2 ===
                                        index ? (
                                          <Button
                                            style={{
                                              color: theme.cor.cor_branco_100,
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              fontWeight:
                                                theme.fonte.bold.medio,
                                              background:
                                                theme.cor.cor_principal_300,
                                            }}
                                            size="small"
                                            endIcon={<PostAddIcon />}
                                            onClick={() => {
                                              const prevAula = getValues();
                                              const id = uuidv4();
                                              const novaAtividade = {
                                                id: uuidv4(),
                                                nome: "",
                                                peso: undefined,
                                                pergunta: "",
                                                tipo:
                                                  avaliacaoTipo ===
                                                  "avaliacao_quiz"
                                                    ? "avaliacao"
                                                    : avaliacaoTipo,
                                                timestamp: null,
                                                alternativa_certa: id,
                                                alternativas: [...Array(1)].map(
                                                  (_: any) => ({
                                                    id: id,
                                                    nome: "",
                                                    timestamp: null,
                                                  })
                                                ),
                                              };
                                              const novoEstado = {
                                                ...prevAula,
                                                atividades: [
                                                  ...(prevAula?.atividades ??
                                                    []),
                                                  novaAtividade,
                                                ],
                                              };
                                              reset(novoEstado, {
                                                keepDirtyValues: false,
                                              });
                                            }}
                                          >
                                            Adicionar Atividade
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                      {((getValues()?.atividades?.length ??
                                        0) as number) > 2 ? (
                                        <Grid item>
                                          {avaliacaoTipo === "avaliacao_quiz" &&
                                          item?.tipo === "quiz" ? (
                                            ""
                                          ) : (
                                            <Button
                                              style={{
                                                color: theme.cor.cor_branco_200,
                                                fontFamily:
                                                  theme.fonte.font.fonte_1,
                                                fontWeight:
                                                  theme.fonte.bold.medio,
                                                background:
                                                  theme.cor.cor_erro_500,
                                                padding: 0,
                                              }}
                                              size="small"
                                              onClick={() => {
                                                let aul: any = getValues();
                                                const atividades =
                                                  aul.atividades.filter(
                                                    (aula: any) =>
                                                      aula?.id !== item?.id
                                                  );

                                                const novoEstado = {
                                                  ...aul,
                                                  atividades: atividades,
                                                };
                                                reset(novoEstado, {
                                                  keepDirtyValues: false,
                                                });
                                              }}
                                            >
                                              <DeleteForeverIcon />
                                            </Button>
                                          )}
                                        </Grid>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="stretch"
                              padding={2}
                              spacing={2}
                            >
                              <Grid item xs={true}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                >
                                  {item?.tipo !== "quiz" ? (
                                    <Grid
                                      item
                                      marginTop={"1rem"}
                                      width={"100%"}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Grid
                                          item
                                          xs={true}
                                          style={{
                                            background:
                                              theme.cor.cor_branco_100,
                                            paddingTop: "1rem",
                                            paddingBottom: "0rem",
                                            paddingRight: ".5rem",
                                          }}
                                        >
                                          <TextField
                                            fullWidth
                                            size="small"
                                            sx={{
                                              ".MuiOutlinedInput-input": {
                                                color: theme.cor.cor_preto_300,
                                              },
                                              "& label": {
                                                color: theme.cor.cor_preto_300,
                                              },
                                              "& label.Mui-focused": {
                                                color:
                                                  theme.cor.cor_principal_500,
                                              },
                                              "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                  borderColor:
                                                    theme.cor.cor_principal_500,
                                                },
                                                "& fieldset": {
                                                  borderColor:
                                                    theme.cor.cor_preto_100,
                                                },
                                                "&:hover fieldset": {
                                                  borderColor:
                                                    theme.cor.cor_principal_500,
                                                },
                                              },
                                            }}
                                            label={"Peso"}
                                            defaultValue={100}
                                            id={`Peso-${index}`}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            {...register(
                                              `atividades.${index}.peso`
                                            )}
                                            error={Boolean(
                                              formState.errors?.atividades?.[
                                                index
                                              ]?.peso
                                            )}
                                            helperText={
                                              formState?.errors?.atividades?.[
                                                index
                                              ]?.peso?.message
                                            }
                                            required
                                            type="number"
                                          ></TextField>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      background: theme.cor.cor_branco_100,
                                      padding: "1.5rem",
                                      paddingTop: "1rem",
                                      paddingBottom: "0rem",
                                      paddingLeft: "0rem",
                                      paddingRight: ".5rem",
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      size="small"
                                      sx={{
                                        ".MuiOutlinedInput-input": {
                                          color: theme.cor.cor_preto_300,
                                        },
                                        "& label": {
                                          color: theme.cor.cor_preto_300,
                                        },
                                        "& label.Mui-focused": {
                                          color: theme.cor.cor_principal_500,
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "&.Mui-focused fieldset": {
                                            borderColor:
                                              theme.cor.cor_principal_500,
                                          },
                                          "& fieldset": {
                                            borderColor:
                                              theme.cor.cor_preto_100,
                                          },
                                          "&:hover fieldset": {
                                            borderColor:
                                              theme.cor.cor_principal_500,
                                          },
                                        },
                                      }}
                                      inputRef={inputRef}
                                      label="Nome da Atividade"
                                      id={`nome-${index}`}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      {...register(`atividades.${index}.nome`)}
                                      error={Boolean(
                                        formState.errors?.atividades?.[index]
                                          ?.nome
                                      )}
                                      helperText={
                                        formState?.errors?.atividades?.[index]
                                          ?.nome?.message
                                      }
                                      required
                                      type="text"
                                    ></TextField>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      background: theme.cor.cor_branco_100,
                                      padding: "1.5rem",
                                      paddingTop: "1rem",
                                      paddingBottom: "0rem",
                                      paddingLeft: "0rem",
                                      paddingRight: ".5rem",
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      size="small"
                                      sx={{
                                        ".MuiOutlinedInput-input": {
                                          color: theme.cor.cor_preto_300,
                                        },
                                        "& label": {
                                          color: theme.cor.cor_preto_300,
                                        },
                                        "& label.Mui-focused": {
                                          color: theme.cor.cor_principal_500,
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "&.Mui-focused fieldset": {
                                            borderColor:
                                              theme.cor.cor_principal_500,
                                          },
                                          "& fieldset": {
                                            borderColor:
                                              theme.cor.cor_preto_100,
                                          },
                                          "&:hover fieldset": {
                                            borderColor:
                                              theme.cor.cor_principal_500,
                                          },
                                        },
                                      }}
                                      label="Pergunta"
                                      id={`pergunta-${index}`}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      {...register(
                                        `atividades.${index}.pergunta`
                                      )}
                                      onChange={(e) =>
                                        alterarPergunta(e, `id-${index}`, true)
                                      }
                                      error={Boolean(
                                        formState.errors?.atividades?.[index]
                                          ?.pergunta
                                      )}
                                      helperText={
                                        formState?.errors?.atividades?.[index]
                                          ?.pergunta?.message
                                      }
                                      required
                                      type="text"
                                    ></TextField>
                                  </Grid>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Grid
                                      item
                                      style={{
                                        width: "100%",
                                        marginTop: "1rem",
                                        borderRadius: "1rem",
                                        marginRight: ".5rem",
                                      }}
                                    >
                                      <div
                                        id={`id-${index}`}
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: validarLink(
                                              getValues()?.atividades?.[index]
                                                ?.pergunta
                                            )
                                              ? "none"
                                              : "",

                                            fontFamily:
                                              theme.fonte.font.fonte_1,
                                            color: theme.cor.cor_preto_300,
                                            fontWeight: theme.fonte.bold.medio,
                                            borderRadius: "1rem",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            height: "25px",
                                            maxHeight: "25px",
                                            minHeight: "25px",
                                            overflow: "hidden",
                                            paddingLeft: "2rem",
                                            paddingRight: "2rem",
                                            border: `dashed  ${theme.cor.cor_principal_500} 1px`,
                                            textAlign: "center",
                                            paddingTop: ".4rem",
                                          }}
                                        >
                                          {validarLink(
                                            getValues()?.atividades?.[index]
                                              ?.pergunta
                                          )
                                            ? ""
                                            : getValues()?.atividades?.[index]
                                                ?.pergunta}
                                        </div>
                                        <iframe
                                          src={
                                            getValues()?.atividades?.[index]
                                              ?.pergunta
                                          }
                                          style={{
                                            display: validarLink(
                                              getValues()?.atividades?.[index]
                                                ?.pergunta
                                            )
                                              ? ""
                                              : "none",
                                            fontFamily:
                                              theme.fonte.font.fonte_1,

                                            color: theme.cor.cor_preto_300,
                                            fontWeight: theme.fonte.bold.medio,
                                            borderRadius: "1rem",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            paddingLeft: "2rem",
                                            paddingRight: "2rem",
                                            textAlign: "center",
                                            margin: "auto",
                                            position: "relative",
                                            top: "50%",
                                            border: "none",
                                            transform: "translateY(0%)",
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={true}
                                paddingRight={"1rem"}
                                paddingTop={"1rem"}
                              >
                                <Grid
                                  container
                                  direction="column"
                                  justifyContent="space-between"
                                  style={{
                                    background: theme.cor.cor_fundo,
                                    borderRadius: "1rem",
                                    boxShadow:
                                      "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                                  }}
                                  padding={"1rem"}
                                  height={"100%"}
                                >
                                  <Grid item xs={true} width={"100%"}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      spacing={4}
                                      rowSpacing={2}
                                      height={"100%"}
                                    >
                                      {item.alternativas?.map(
                                        (alternativa: any, opcaoIndex: any) => (
                                          <Grid
                                            item
                                            key={`${alternativa.id}-${
                                              getValues()?.atividades?.[index]
                                                ?.alternativa_certa
                                            }-${item.alternativas.length}`}
                                            xs={6}
                                          >
                                            <Grid
                                              container
                                              direction="row"
                                              justifyContent="center"
                                              alignItems="center"
                                              spacing={2}
                                              style={{ marginTop: "0.4rem" }}
                                            >
                                              <Grid item>
                                                {getValues()?.atividades?.[
                                                  index
                                                ]?.tipo !== "quiz" &&
                                                avaliacaoTipo !== "quiz" ? (
                                                  <Radio
                                                    style={{
                                                      color:
                                                        theme.cor
                                                          .cor_principal_500,
                                                    }}
                                                    onClick={() => {
                                                      {
                                                        watch(
                                                          `atividades.${index}.alternativa_certa`
                                                        );

                                                        setValue(
                                                          `atividades.${index}.alternativa_certa`,
                                                          alternativa.id
                                                        );

                                                        watch(
                                                          `atividades.${index}.alternativa_certa`
                                                        );
                                                      }
                                                    }}
                                                    checked={
                                                      getValues()?.atividades?.[
                                                        index
                                                      ]?.alternativa_certa ===
                                                      alternativa.id
                                                    }
                                                    value={alternativa.id}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Grid>
                                              <Grid item>
                                                <TextField
                                                  fullWidth
                                                  size="small"
                                                  sx={{
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    ".MuiOutlinedInput-input": {
                                                      color:
                                                        theme.cor.cor_preto_300,
                                                    },
                                                    "& label": {
                                                      color:
                                                        theme.cor.cor_preto_300,
                                                    },
                                                    "& label.Mui-focused": {
                                                      color:
                                                        theme.cor
                                                          .cor_principal_500,
                                                    },
                                                    "& .MuiOutlinedInput-root":
                                                      {
                                                        "&.Mui-focused fieldset":
                                                          {
                                                            borderColor:
                                                              theme.cor
                                                                .cor_principal_500,
                                                          },
                                                        "& fieldset": {
                                                          borderColor:
                                                            theme.cor
                                                              .cor_preto_100,
                                                        },
                                                        "&:hover fieldset": {
                                                          borderColor:
                                                            theme.cor
                                                              .cor_principal_500,
                                                        },
                                                      },
                                                  }}
                                                  {...register(
                                                    `atividades.${index}.alternativas.${opcaoIndex}.nome`
                                                  )}
                                                  id={`opcao-${index}-${opcaoIndex}`}
                                                  required
                                                  error={Boolean(
                                                    formState.errors
                                                      ?.atividades?.[index]
                                                      ?.alternativas?.[
                                                      opcaoIndex
                                                    ]?.nome
                                                  )}
                                                  helperText={
                                                    formState?.errors
                                                      ?.atividades?.[index]
                                                      ?.alternativas?.[
                                                      opcaoIndex
                                                    ]?.nome?.message
                                                  }
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  type="text"
                                                  style={{
                                                    textAlign: "center",
                                                    fontFamily:
                                                      theme.fonte.font.fonte_1,
                                                    color:
                                                      theme.cor.cor_preto_300,
                                                    background:
                                                      theme.cor
                                                        .cor_principal_200,
                                                    borderRadius: "1rem",
                                                    padding: ".5rem",
                                                    fontWeight:
                                                      theme.fonte.bold.medio,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item>
                                                {item.alternativas.length >
                                                1 ? (
                                                  <CloseIcon
                                                    style={{
                                                      cursor: "pointer",
                                                      color:
                                                        theme.cor.cor_preto_300,
                                                    }}
                                                    onClick={(_) => {
                                                      let prevAula: any =
                                                        getValues();
                                                      let alternativasFiltradas: any =
                                                        null;
                                                      let existeId: any = null;
                                                      const novaAula = {
                                                        ...getValues(),
                                                        atividades:
                                                          prevAula.atividades.map(
                                                            (
                                                              atividade: any,
                                                              i: any
                                                            ) => {
                                                              if (i === index) {
                                                                alternativasFiltradas =
                                                                  atividade.alternativas.filter(
                                                                    (r: any) =>
                                                                      r.id !==
                                                                      alternativa.id
                                                                  );

                                                                existeId =
                                                                  alternativasFiltradas.map(
                                                                    (e: any) =>
                                                                      e.id
                                                                  );

                                                                if (
                                                                  atividade &&
                                                                  existeId &&
                                                                  existeId?.includes(
                                                                    atividade?.alternativa_certa
                                                                  ) === false
                                                                ) {
                                                                  atividade.alternativa_certa =
                                                                    existeId[1];
                                                                }

                                                                return {
                                                                  ...atividade,
                                                                  alternativa_certa:
                                                                    existeId[0],
                                                                  alternativas:
                                                                    [
                                                                      ...alternativasFiltradas,
                                                                    ],
                                                                };
                                                              }

                                                              return atividade;
                                                            }
                                                          ),
                                                      };
                                                      reset(novaAula, {
                                                        keepDirtyValues: false,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={true} width={"100%"}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      spacing={2}
                                      marginTop={"2rem"}
                                    >
                                      <Grid item>
                                        <Button
                                          style={{
                                            color: theme.cor.cor_branco_100,
                                            background:
                                              theme.cor.cor_principal_500,
                                            fontFamily:
                                              theme.fonte.font.fonte_1,
                                          }}
                                          size="small"
                                          variant="contained"
                                          endIcon={<AddIcon />}
                                          onClick={() => {
                                            const prevAula: any = getValues();
                                            const novo = {
                                              ...prevAula,
                                              atividades:
                                                prevAula.atividades.map(
                                                  (atividade: any, i: any) => {
                                                    if (i === index) {
                                                      const novaalternativa = {
                                                        id: uuidv4(),
                                                        nome: "",
                                                        timestamp: null,
                                                      };
                                                      return {
                                                        ...atividade,
                                                        alternativas: [
                                                          ...atividade.alternativas,
                                                          novaalternativa,
                                                        ],
                                                      };
                                                    }
                                                    return atividade;
                                                  }
                                                ),
                                            };
                                            reset(novo, {
                                              keepDirtyValues: false,
                                            });
                                          }}
                                        >
                                          Alternativa
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AulaItem;
