import {GoogleGenerativeAI} from "@google/generative-ai";

const MODEL_NAME = "gemini-pro";
const API_KEY = process.env.REACT_APP_API_GEMINI;
const genAI = new GoogleGenerativeAI(API_KEY);

const chat_gemini = async (texto) => {
  try {
    const model = genAI.getGenerativeModel({ model: MODEL_NAME });
    const prompt = texto;
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();

    return text;
  } catch (error) {
    console.error("Error generating content:", error);
    throw error;
  }
};

export default chat_gemini;
