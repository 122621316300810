import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {LoadingButton} from "@mui/lab";
import {Button, Grid, Link} from "@mui/material";
import {useTheme} from "styled-components";
import {MenuSupeior} from "../theme";

const MenuSuperior = ({
  texto,
  ativo = true,
  uuidDisciplina = null,
  setOpen = null,
  setProgramar = null,
  ativarBtn = null,
  publicar = null,
}: any) => {
  const theme = useTheme();
  if (!ativo) return <></>;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: ".5rem", paddingBottom: ".8rem" }}
        spacing={2}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              {((window.location.pathname.includes("aulas") &&
                window.location.pathname.includes("turma")) ||
                (window.location.pathname.includes("trilhas") &&
                  window.location.pathname.includes("turma"))) &&
              uuidDisciplina ? (
                <Link
                  href={`/${
                    window.location.href.includes("trilhas")
                      ? "trilhas"
                      : "aulas"
                  }/turma/`}
                >
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      fontFamily: theme.fonte.font.fonte_1,
                      background: theme.cor.cor_erro_500,
                      color: theme.cor.cor_branco_100,
                    }}
                  >
                    Voltar
                  </Button>
                </Link>
              ) : (
                ""
              )}
            </Grid>
            <Grid item>
              <MenuSupeior
                style={{
                  marginLeft: "1.5rem",
                  fontFamily: theme.fonte.font.fonte_1,
                  color: theme.cor.cor_preto_300,
                  fontWeight: theme.fonte.bold.semi_bold,
                }}
              >
                {texto}
              </MenuSupeior>
            </Grid>
          </Grid>
        </Grid>
        {(window.location.pathname.includes("aulas") ||
          window.location.pathname.includes("turma")) &&
        uuidDisciplina ? (
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                {window.location.href.includes("/aulas") ? (
                  <Button
                    onClick={(e: any) => {
                      setOpen(true);
                      setProgramar(false);
                    }}
                    size="small"
                    variant="outlined"
                    style={{
                      fontFamily: theme.fonte.font.fonte_1,
                      color: theme.cor.cor_principal_500,
                      borderColor: theme.cor.cor_principal_500,
                    }}
                    endIcon={<DateRangeIcon />}
                  >
                    Data de entrega
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{
                    fontFamily: theme.fonte.font.fonte_1,
                    background: theme.cor.cor_verde_500,
                    color: theme.cor.cor_branco_100,
                  }}
                  loading={ativarBtn}
                  loadingPosition="end"
                  endIcon={<CheckCircleOutlineIcon />}
                >
                  {publicar ? "PUBLICAR" : "PROGRAMAR"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default MenuSuperior;
