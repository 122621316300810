import {Button, Container, Grid, Link} from "@mui/material";
import {Result} from "antd";
import {useTheme} from "styled-components";

const Erro = () => {
  const theme = useTheme();

  return (
    <Container style={{ height: "100vh" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <Result
          style={{
            color: theme.cor.cor_preto_300,
            fontFamily: theme.fonte.font.fonte_1,
          }}
          status="warning"
          title="Existem alguns problemas com sua operação."
          extra={
            <Link href={"/"}>
              <Button
                variant="contained"
                style={{
                  background: theme.cor.cor_principal_500,
                  color: theme.cor.cor_branco_100,
                  fontFamily: theme.fonte.font.fonte_1,
                }}
                size="small"
                key="console"
              >
                Voltar
              </Button>
            </Link>
          }
        />
      </Grid>
    </Container>
  );
};

export default Erro;
