import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTheme} from "styled-components";

export default function Deletar({
  open,
  handleClose,
  item,
  funcaoDeletar,
  titulo = false,
}: any) {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { borderRadius: "1.5rem", background: theme.cor.cor_branco_100 },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.cor.cor_preto_400,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            gutterBottom
            style={{
              fontFamily: theme.fonte.font.fonte_1,
              color: theme.cor.cor_preto_300,
            }}
          >
            {`Têm certeza que deseja remover `}{" "}
            <b>
              {titulo
                ? item?.titulo
                : item?.username
                ? item?.username
                : item?.nome}
            </b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              background: theme.cor.cor_erro_500,
              color: theme.cor.cor_branco_200,
              fontFamily: theme.fonte.font.fonte_1,
            }}
            size="small"
            variant={"contained"}
            onClick={handleClose}
          >
            Não
          </Button>
          <Button
            style={{
              background: theme.cor.cor_principal_500,
              color: theme.cor.cor_branco_100,
              fontFamily: theme.fonte.font.fonte_1,
            }}
            size="small"
            autoFocus
            onClick={(_) => {
              funcaoDeletar();
              handleClose();
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
