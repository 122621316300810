import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { keycloak } from ".";
import "./App.css";
import Aula from "./components/aula";
import AulaItem from "./components/aula-criar";
import AulaTurma from "./components/aula-turma";
import Aulas from "./components/aulas";
import Chat from "./components/chat-gemini";
import Configuracoes from "./components/configuracoes";
import Home from "./components/home";
import Informacoes from "./components/informacoes";
import Metricas from "./components/metricas";
import Recados from "./components/recados";
import Sair from "./components/sair";
import { theme } from "./components/theme";
import Trilha from "./components/trilha";
import Vidoteca from "./components/videoteca";
import { AuthToken, getTipoConta, setTitle } from "./types";
import Turmas from "./components/turmas";
import Admin from "./components/admin";

function App() {
  const [decodedToken, setDecodedToken] = useState(
    keycloak.token && (jwtDecode(keycloak.token) as AuthToken)
  );

  useEffect(() => {
    let contratoEnv = process.env.REACT_APP_CONTRATO as keyof typeof theme;

    if (
      decodedToken &&
      theme.hasOwnProperty(decodedToken?.contrato as keyof typeof theme)
    ) {
      setDecodedToken((decodedToken: any) => {
        return {
          ...decodedToken,
          tema: theme[decodedToken?.contrato as keyof typeof theme],
        };
      });
      setTitle(decodedToken?.contrato);
    } else if (theme.hasOwnProperty(contratoEnv)) {
      setDecodedToken((decodedToken: any) => {
        return {
          ...decodedToken,
          contrato: contratoEnv,
          tema: theme[contratoEnv as keyof typeof theme],
        };
      });
      setTitle(contratoEnv);
    }

    // const temas = ["ips", "govtech", "sappens", "cesan"];
    // const testar_tema = "cesan" as keyof typeof theme;
    // setDecodedToken((decodedToken: any) => {
    //   return {
    //     ...decodedToken,
    //     contrato: testar_tema,
    //     tema: theme[testar_tema],
    //   };
    // });
    // setTitle(testar_tema);
  }, []);

  if (!decodedToken || !decodedToken?.contrato || !decodedToken?.tema) {
    return <></>;
  }

  const tipoConta = getTipoConta(decodedToken);
  // return <HomeIPS></HomeIPS>;
  if (tipoConta === "aluno" || tipoConta === "professor")
    return (
      <ThemeProvider theme={decodedToken?.tema}>
        <BrowserRouter>
          {decodedToken.contrato === "cesan" ? "" : <Chat />}
          <Routes>
            <Route
              path="/home"
              element={<Home decodedToken={decodedToken} />}
            />
            <Route path="/" element={<Home decodedToken={decodedToken} />} />

            <Route index element={<Home decodedToken={decodedToken} />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route
              path="/videoteca"
              element={<Vidoteca decodedToken={decodedToken} />}
            />
            <Route
              path="/videoteca/:uuid"
              element={<Aula decodedToken={decodedToken} />}
            />
            <Route
              path="/configuracoes"
              element={
                <Configuracoes
                  decodedToken={decodedToken}
                  setDecodedToken={setDecodedToken}
                />
              }
            />
            {decodedToken.contrato === "cesan" ? (
              ""
            ) : (
              <>
                <Route
                  path="/aulas/:uuid"
                  element={<Aula decodedToken={decodedToken} />}
                />
                <Route
                  path="/aulas"
                  element={<Aulas decodedToken={decodedToken} />}
                />
              </>
            )}
            <Route
              path="/informacoes"
              element={<Informacoes decodedToken={decodedToken} />}
            />
            {/* <Route
              path="/fale"
              element={<FaleConosco decodedToken={decodedToken} />}
            /> */}
            <Route
              path="/metricas"
              element={<Metricas decodedToken={decodedToken} />}
            />
            <Route
              path="/sair"
              element={<Sair decodedToken={decodedToken} />}
            />
            <Route
              path="/trilhas"
              element={<Trilha decodedToken={decodedToken} />}
            />
            <Route
              path="/trilhas/:uuid"
              element={<Aula decodedToken={decodedToken} />}
            />
            <Route
              path="/trilhas/turma/:uuidTurma/:uuidDisciplina"
              element={<AulaItem decodedToken={decodedToken} />}
            />
            <Route
              path="/trilhas/turma/:uuidTurma/:uuidDisciplina/:uuidAula"
              element={<AulaItem decodedToken={decodedToken} />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={decodedToken?.tema}>
      <BrowserRouter>
        {decodedToken.contrato === "cesan" ? "" : <Chat />}
        <Routes>
          {tipoConta === "admin" ? (
            <Route
              path="/admin"
              element={<Admin decodedToken={decodedToken} />}
            />
          ) : (
            ""
          )}
          <Route path="/home" element={<Home decodedToken={decodedToken} />} />
          <Route path="/" element={<Home decodedToken={decodedToken} />} />
          <Route
            path="/trilhas"
            element={<Trilha decodedToken={decodedToken} />}
          />
          <Route
            path="/trilhas/turma"
            element={<AulaTurma decodedToken={decodedToken} />}
          />
          <Route
            path="/turmas"
            element={<Turmas decodedToken={decodedToken} />}
          />
          <Route
            path="/trilhas/:uuid"
            element={<Aula decodedToken={decodedToken} />}
          />
          <Route
            path="/trilhas/turma/:uuidTurma/:uuidDisciplina"
            element={<AulaItem decodedToken={decodedToken} />}
          />
          <Route
            path="/trilhas/turma/:uuidTurma/:uuidDisciplina/:uuidAula"
            element={<AulaItem decodedToken={decodedToken} />}
          />
          <Route index element={<Home decodedToken={decodedToken} />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route
            path="/videoteca"
            element={<Vidoteca decodedToken={decodedToken} />}
          />
          <Route
            path="/videoteca/:uuid"
            element={<Aula decodedToken={decodedToken} />}
          />
          <Route
            path="/configuracoes"
            element={
              <Configuracoes
                decodedToken={decodedToken}
                setDecodedToken={setDecodedToken}
              />
            }
          />
          {decodedToken.contrato === "cesan" ? (
            ""
          ) : (
            <>
              <Route
                path="/aulas/:uuid"
                element={<Aula decodedToken={decodedToken} />}
              />
              <Route
                path="/aulas"
                element={<Aulas decodedToken={decodedToken} />}
              />
              <Route
                path="/aulas/turma"
                element={<AulaTurma decodedToken={decodedToken} />}
              />
              <Route
                path="/aulas/turma/:uuidTurma/:uuidDisciplina"
                element={<AulaItem decodedToken={decodedToken} />}
              />
              <Route
                path="/aulas/turma/:uuidTurma/:uuidDisciplina/:uuidAula"
                element={<AulaItem decodedToken={decodedToken} />}
              />
              {/* <Route
                path="/aulas/gestor"
                element={<AulasGestor decodedToken={decodedToken} />}
              /> */}
            </>
          )}
          <Route
            path="/informacoes"
            element={<Informacoes decodedToken={decodedToken} />}
          />
          {/* <Route
            path="/fale"
            element={<FaleConosco decodedToken={decodedToken} />}
          /> */}
          <Route
            path="/metricas"
            element={<Metricas decodedToken={decodedToken} />}
          />
          <Route
            path="/recados"
            element={<Recados decodedToken={decodedToken} />}
          />
          <Route path="/sair" element={<Sair decodedToken={decodedToken} />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
