import {yupResolver} from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTheme} from "styled-components";
import * as yup from "yup";
import Deletar from "../confirmar/deletar";
import CardMenu, {MenuPhone} from "../menu";
import MenuSuperior from "../menuSuperior";
import {mobile, StyledTableTurma} from "../theme";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import api from "../axios/axios";
import CloseIcon from "@mui/icons-material/Close";
import Paginacao from "../paginacao";
import {toast} from "react-toastify";
import Erro from "../erro";
import Carregar from "../carregar";
import {LoadingButton} from "@mui/lab";

const schemaCadastro = yup
  .object()
  .shape({
    firstName: yup.string().required("Por favor, preencha este campo."),
    lastName: yup.string().required("Por favor, preencha este campo."),
    username: yup.string().required("Por favor, preencha este campo."),
    tipo: yup.string().required("Por favor, preencha este campo."),
    email: yup.string().email("Email inválido"),
    senha1: yup
      .string()
      .oneOf([yup.ref("senha2"), ""], "As senhas precisam ser iguais."),

    senha2: yup
      .string()
      .oneOf([yup.ref("senha1"), ""], "As senhas precisam ser iguais."),
  })
  .required();

const Admin = ({ decodedToken }: any) => {
  const theme = useTheme();

  const [pessoaSelecao, setPessoaSelecao] = useState<any>({ id: -1 });
  const [pessoas, setPessoas] = useState<any>({
    total: 0,
    items: [],
  });
  const [open, setOpen] = useState(false);
  const [editar, setEditar] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [configuracao, setConfiguracao] = useState({
    loading: false,
    carregar: true,
    erro: false,
  });
  const valores_padrao = {
    firstName: "",
    lastName: "",
    senha1: "",
    senha2: "",
    tipo: "aluno",
    email: "",
    username: "",
  };
  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: valores_padrao,
    resolver: yupResolver(schemaCadastro),
  });

  const funcaoDeletar = () => {
    toast.info("Deletando pessoa");

    api
      .delete(`admin/${pessoaSelecao?.username}`)
      .then(() => {
        setPessoas((antes: any) => {
          return {
            ...antes,
            items: antes?.items.filter(
              (e: any) => String(e.username) !== String(pessoaSelecao.username)
            ),
          };
        });
        toast.success("Pessoa deletada");
      })
      .catch(() => {
        toast.error("Pessoa não deletada");
      });
  };

  const cadastro = (data: any) => {
    setConfiguracao((antes: any) => {
      return { ...antes, loading: true };
    });
    if (editar) {
      toast.info("Editando pessoa");
      api
        .post(`/admin/${data.username}`, data)
        .then((r) => {
          setPessoas(() => {
            let items = pessoas.items.map((e: any) => {
              if (String(e.username) === String(data.username)) {
                return data;
              } else {
                return e;
              }
            });

            return { ...pessoas, items: items };
          });
          reset(valores_padrao);
          setEditar(false);
          toast.success("Pessoa editada");
        })
        .catch(() => {
          toast.error("Pessoa não editada");
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return { ...antes, loading: false };
          });
        });
    } else {
      toast.info("Criando pessoa");
      api
        .post(`/admin`, data)
        .then((r) => {
          setPessoas({ ...pessoas, items: [data, ...pessoas.items] });

          reset(valores_padrao);
          toast.success("Pessoa criada");
        })
        .catch(() => {
          toast.error("Pessoa não criada");
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return { ...antes, loading: false };
          });
        });
    }
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    api
      .get("/admin")
      .then((r: any) => setPessoas(r?.data))
      .then(() =>
        setConfiguracao((antes: any) => {
          return { ...antes, carregar: false };
        })
      );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePage = (page: any) => {
    api.get("/admin" + "?&page=" + page).then((r) => {
      setPessoas(r.data);
    });

    return;
  };

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.carregar) return <Carregar />;

  return (
    <>
      <Deletar
        open={open}
        handleClose={handleClose}
        item={pessoaSelecao}
        funcaoDeletar={funcaoDeletar}
      />
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={15}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            ativo={mobile(windowSize) ? true : false}
            decodedToken={decodedToken}
          />
          <Grid
            item
            style={{
              background: theme.cor.cor_branco_100,
              padding: 0,
              margin: 0,
            }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Admin"} />
          </Grid>
          <Grid item padding={3} marginTop={mobile(windowSize) ? "" : "4rem"}>
            <Grid
              item
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                borderRadius: "1rem",
                background: theme.cor.cor_branco_100,
                paddingBottom: "1rem",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  background: theme.cor.cor_principal_500,
                  color: theme.cor.cor_branco_100,
                  fontFamily: theme.fonte.font.fonte_1,
                  fontSize: "18px",
                  fontWeight: theme.fonte.bold.medio,
                  padding: ".5rem",
                  borderTopLeftRadius: ".5rem",
                  borderTopRightRadius: ".5rem",
                }}
              >
                <Grid item style={{ paddingLeft: "1rem" }}>
                  GERENCIADOR DE CADASTRO
                </Grid>
              </Grid>

              <Grid item>
                <form onSubmit={handleSubmit(cadastro)}>
                  <Grid item xs={12} style={{ paddingTop: "1rem" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={10} sm={2}>
                        <Controller
                          name="firstName"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.firstName)}
                              helperText={formState?.errors?.firstName?.message}
                              label={"Nome"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false, // Força o label a flutuar somente quando houver um valor
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={10} sm={2}>
                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.firstName)}
                              helperText={formState?.errors?.firstName?.message}
                              label={"Nome"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={10} sm={4}>
                        <Controller
                          name="username"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              disabled={editar}
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.username)}
                              helperText={formState?.errors?.username?.message}
                              label={"Matrícula"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={10} sm={4} sx={{ maxWidth: "100%" }}>
                        <FormControl
                          sx={{ maxWidth: "100%" }}
                          fullWidth
                          size="small"
                        >
                          <InputLabel
                            required
                            style={{ color: theme.cor.cor_preto_500 }}
                          >
                            Tipo
                          </InputLabel>
                          <Controller
                            name="tipo"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <Select
                                {...field}
                                inputRef={ref}
                                value={value || "aluno"}
                                id="tipo"
                                label="tipo"
                                style={{
                                  color: theme.cor.cor_preto_300,
                                  height: "33px",
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                required
                                {...register("tipo")}
                                sx={{
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: theme.cor.cor_preto_100,
                                    },

                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"aluno"}
                                  value={"aluno"}
                                >
                                  Aluno
                                </MenuItem>

                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"professor"}
                                  value={"professor"}
                                >
                                  Professor
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"gestor"}
                                  value={"gestor"}
                                >
                                  Gestor
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"administrador"}
                                  value={"administrador"}
                                >
                                  Administrador
                                </MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={10} sm={4}>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              error={Boolean(formState.errors?.email)}
                              helperText={formState?.errors?.email?.message}
                              label={"Email"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid xs={12} marginTop={".5rem"}></Grid>
                      <Grid item xs={10} sm={2}>
                        <Controller
                          name="senha1"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required={!editar}
                              error={Boolean(
                                formState.errors?.senha2 ||
                                  formState.errors?.senha1
                              )}
                              helperText={
                                formState?.errors?.senha2?.message ||
                                formState?.errors?.senha1?.message
                              }
                              label={"Senha"}
                              type="password"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={10} sm={2}>
                        <Controller
                          name="senha2"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required={!editar}
                              error={Boolean(
                                formState.errors?.senha2 ||
                                  formState.errors?.senha1
                              )}
                              helperText={
                                formState?.errors?.senha2?.message ||
                                formState?.errors?.senha1?.message
                              }
                              label={"Confirmar senha"}
                              type="password"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}></Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <LoadingButton
                            loading={configuracao?.loading}
                            loadingPosition="end"
                            style={{
                              color: theme.cor.cor_branco_100,
                              fontFamily: theme.fonte.font.fonte_1,
                              fontWeight: theme.fonte.bold.medio,
                              background: theme.cor.cor_verde_500,
                              marginTop: ".3rem",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                            }}
                            size="small"
                            type="submit"
                            endIcon={
                              <GroupAddIcon
                                style={{
                                  fill: theme.cor.cor_branco_100,
                                  marginTop: "-.1rem",
                                }}
                              />
                            }
                          >
                            {editar ? "Editar" : "Cadastrar"}
                          </LoadingButton>
                        </Grid>
                        <Grid item>
                          <LoadingButton
                            loadingPosition="end"
                            loading={configuracao?.loading}
                            style={{
                              color: theme.cor.cor_branco_100,
                              fontFamily: theme.fonte.font.fonte_1,
                              fontWeight: theme.fonte.bold.medio,
                              background: theme.cor.cor_erro_500,
                              marginTop: ".3rem",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                            }}
                            size="small"
                            endIcon={
                              <CloseIcon
                                style={{
                                  fill: theme.cor.cor_branco_100,
                                  marginTop: "-.1rem",
                                }}
                              />
                            }
                            onClick={() => {
                              reset(valores_padrao);
                              setEditar(false);
                            }}
                          >
                            Cancelar
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {pessoas?.items?.length > 0 ? (
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="space-between"
                    padding={"2rem"}
                    paddingTop={"1.5rem"}
                    paddingBottom={0}
                  >
                    <StyledTableTurma>
                      <TableBody
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                          borderRadius: "1rem",
                        }}
                      >
                        {pessoas?.items?.map((p: any, i: number) => (
                          <TableRow
                            key={p.username}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              background: theme.cor.cor_branco_200,
                            }}
                          >
                            <TableCell
                              align="left"
                              style={{
                                borderBottom:
                                  pessoas?.items?.length - 1 !== i
                                    ? "1px solid" + theme.cor.cor_branco_500
                                    : "",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: theme.fonte.bold.medio,
                                  fontSize: "14px",
                                  fontFamily: theme.fonte.font.normal,
                                  color: theme.cor.cor_preto_300,
                                  padding: 0,
                                  margin: 0,
                                  paddingLeft: "1rem",
                                }}
                              >
                                {p?.firstName}
                                {p?.lastName ? " " + p.lastName : ""}
                              </p>
                            </TableCell>
                            <TableCell
                              style={{
                                borderLeft:
                                  "1px solid" + theme.cor.cor_branco_500,

                                borderBottom:
                                  pessoas?.items?.length - 1 !== i
                                    ? "1px solid" + theme.cor.cor_branco_500
                                    : "",
                              }}
                              align="center"
                            >
                              <p
                                style={{
                                  fontWeight: theme.fonte.bold.medio,
                                  fontSize: "14px",
                                  fontFamily: theme.fonte.font.normal,
                                  color: theme.cor.cor_preto_300,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {p.username}
                              </p>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "65px",
                                borderBottom:
                                  pessoas?.items?.length - 1 !== i
                                    ? "1px solid" + theme.cor.cor_branco_500
                                    : "",
                              }}
                              align="center"
                            >
                              <Grid></Grid>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item>
                                  <BorderColorIcon
                                    style={{
                                      height: "20px",
                                      marginTop: ".3rem",
                                      fill: theme.cor.cor_preto_300,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setEditar(true);
                                      reset(p);
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <DeleteIcon
                                    style={{
                                      height: "20px",
                                      marginTop: ".3rem",
                                      fill: theme.cor.cor_erro_500,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setPessoaSelecao(p);
                                      setOpen(true);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </StyledTableTurma>
                    <Grid
                      item
                      style={{ marginTop: "1.5rem", marginBottom: "-1rem" }}
                    >
                      <Paginacao item={pessoas} handlePage={handlePage} />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Admin;
