import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Link,
    Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useTheme} from "styled-components";
import AlternativaFoto from "../../img/PNG (1).png";
import {hexToRgb, validarLink} from "../../types";
import api from "../axios/axios";
import Carregar from "../carregar";
import Erro from "../erro";
import CardMenu, {MenuPhone} from "../menu";
import MenuSuperior from "../menuSuperior";
import {mobile} from "../theme";
import {Resumo} from "./resumo";
import Vimeo from "@vimeo/player";

const Aula = ({ decodedToken }: any) => {
  const theme = useTheme();
  const [tempo, setTempo] = useState<any>(null);
  const [aula, setAula] = useState<any>();
  const [alternativa, setalternativa] = useState<any>();
  const [selecao, setSelecao] = useState(0);
  const { uuid } = useParams();
  const [open, setOpen] = useState(false);
  const [enviar, setEnviar] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [configuracao, setConfiguracao] = useState<any>({
    loading: true,
    erro: false,
  });
  const iframeRef = useRef<any>(null);
  const [mostrarProximo, setMostrarProximo] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        if (
          aula?.link &&
          mostrarProximo === 0 &&
          aula?.link?.toLowerCase().indexOf("vimeo") === -1
        ) {
          setMostrarProximo(1);
        } else if (mostrarProximo === 0 && aula?.link && iframeRef.current) {
          const player = new Vimeo(iframeRef.current);

          player.on("ended", function () {
            setMostrarProximo(1);
          });
        }
      } catch {}
    }, 3000);

    return () => clearInterval(intervalId);
  }, [aula?.link]);

  useEffect(() => {
    setTempo(new Date().getTime());
  }, []);

  const SetSelecao = (index: any) => {
    setalternativa((antes: any) => {
      const novoArray = antes?.map((item: any, index: any) => {
        if (index === selecao) {
          return {
            ...item,
            tempo: item.tempo + (new Date().getTime() - tempo) / 1000,
          };
        }
        return item;
      });
      return novoArray;
    });
    setSelecao(index);
    setTempo(new Date().getTime());
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    api
      .get("submissao/aula/" + uuid)
      .then((r) => {
        r.data.atividades.unshift({ id: -1 });
        r.data.atividades.push({ id: -1 });
        let vetorVazio: any = [];
        r.data.atividades.map((r: any) =>
          vetorVazio.push({ id: null, tempo: 0, id_atividade: r?.id })
        );
        setalternativa(vetorVazio);
        setAula(r.data);
      })
      .then((e: any) =>
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        })
      )
      .catch((e) => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
            erro: true,
          };
        });
        console.log(e);
      });
  }, []);

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  return (
    <>
      {enviar !== 2 ? (
        <React.Fragment>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
              sx: {
                borderRadius: "1.5rem",
                background: theme.cor.cor_branco_100,
              },
            }}
          >
            <Grid item style={{ margin: "1rem" }}>
              <DialogTitle sx={{ p: 3 }}>
                <IconButton
                  aria-label="close"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme.cor.cor_preto_400,
                  }}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              {enviar === 0 ? (
                <>
                  <DialogContent style={{ paddingBottom: "3.5rem" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        style={{
                          position: "absolute",
                          backgroundColor: `rgba(${hexToRgb(
                            theme.cor.cor_verde_500
                          )}, 0.2)`,
                          borderRadius: "50%",
                          padding: ".3rem",
                          cursor: "pointer",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CheckCircleOutlineIcon
                            style={{
                              color: theme.cor.cor_verde_500,
                              borderRadius: "100%",
                              height: 35,
                              width: 35,
                              paddingBottom: "0rem",
                              marginBottom: "0rem",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          gutterBottom
                          style={{
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            fontSize: "19px",
                            fontWeight: theme.fonte.bold.medio,
                          }}
                        >
                          Confirmação de alternativas
                        </Typography>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogContent>
                    <Typography
                      style={{
                        fontFamily: theme.fonte.font.fonte_1,
                        color: theme.cor.cor_preto_300,
                        fontSize: "14px",
                        marginTop: "-1rem",
                        textAlign: "center",
                      }}
                      gutterBottom
                    >
                      Você está prestes a enviar suas alternativas. Antes de
                      prosseguir, queremos ter certeza de que você revisou suas
                      alternativas e está satisfeito com elas. Se está inseguro,
                      esta é a sua chance de voltar e fazer quaisquer ajustes
                      necessários.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      style={{
                        color: theme.cor.cor_erro_500,
                        fontFamily: theme.fonte.font.fonte_1,
                        borderColor: theme.cor.cor_erro_500,
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => setOpen(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{
                        background: theme.cor.cor_verde_500,
                        color: theme.cor.cor_branco_100,
                        fontFamily: theme.fonte.font.fonte_1,
                      }}
                      size="small"
                      autoFocus
                      onClick={(_) => {
                        setEnviar(1);
                      }}
                    >
                      Enviar
                    </Button>
                  </DialogActions>
                </>
              ) : enviar === 1 ? (
                <>
                  <DialogContent>
                    <Typography
                      style={{
                        fontFamily: theme.fonte.font.fonte_1,
                        color: theme.cor.cor_preto_300,
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                      gutterBottom
                    >
                      <EmojiEventsIcon
                        style={{
                          color: theme.cor.cor_verde_200,
                          width: "150px",
                          height: "150px",
                        }}
                      />
                      <p
                        style={{
                          color: theme.cor.cor_preto_300,
                          fontSize: "19px",
                        }}
                      >
                        Alternativas Envidas!
                      </p>
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          style={{
                            background: theme.cor.cor_principal_500,
                            color: theme.cor.cor_branco_100,
                            fontFamily: theme.fonte.font.fonte_1,
                          }}
                          size="small"
                          autoFocus
                          onClick={(_) => {
                            setOpen(false);
                            setEnviar(2);
                          }}
                          endIcon={<NavigateNextIcon />}
                        >
                          Resultado
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Dialog>
        </React.Fragment>
      ) : (
        ""
      )}
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={window.location.href.includes("/aulas") ? 3 : 20}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            height={"100%"}
          >
            <Grid item xs={12} marginTop={mobile(windowSize) ? "" : "4rem"}>
              <MenuPhone
                decodedToken={decodedToken}
                ativo={mobile(windowSize) ? true : false}
              />
              <Grid
                item
                xs={12}
                style={{
                  background: theme.cor.cor_branco_100,
                  padding: 0,
                  margin: 0,
                }}
              >
                <MenuSuperior ativo={mobile(windowSize)} texto={aula?.nome} />
              </Grid>
              <Grid item xs={true} height={"auto"}>
                {enviar !== 2 ? (
                  <>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                          marginTop={"1.5rem"}
                          marginLeft={"2rem"}
                          marginRight={"1rem"}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            {aula?.atividades?.map(
                              (atividade: any, index: any) => (
                                <Grid item key={index}>
                                  {0 === index ? (
                                    <Button
                                      onClick={(_) => SetSelecao(index)}
                                      style={{
                                        marginRight: "1rem",
                                        background:
                                          selecao >= index
                                            ? theme.cor.cor_principal_500
                                            : theme.cor.cor_branco_100,
                                        color:
                                          selecao >= index
                                            ? theme.cor.cor_branco_200
                                            : theme.cor.cor_preto_300,
                                        fontFamily: theme.fonte.font.fonte_1,
                                      }}
                                      variant={"contained"}
                                      size="small"
                                      disabled={
                                        mostrarProximo === 1 ? false : true
                                      }
                                    >
                                      Aula
                                    </Button>
                                  ) : index === aula?.atividades?.length - 1 ? (
                                    aula?.atividades?.filter(
                                      (item: any) => item?.id !== -1
                                    )?.length > 0 ? (
                                      <Button
                                        onClick={(_) => SetSelecao(index)}
                                        style={{
                                          marginRight: "1rem",
                                          background:
                                            selecao === index
                                              ? theme.cor.cor_principal_500
                                              : theme.cor.cor_branco_100,
                                          color:
                                            selecao === index
                                              ? theme.cor.cor_branco_200
                                              : theme.cor.cor_preto_300,
                                          fontFamily: theme.fonte.font.fonte_1,
                                        }}
                                        disabled={
                                          mostrarProximo === 1 ? false : true
                                        }
                                        variant={"contained"}
                                        size="small"
                                      >
                                        Resumo
                                      </Button>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    <Button
                                      onClick={(_) => SetSelecao(index)}
                                      style={{
                                        marginRight: "1rem",
                                        background:
                                          selecao === index ||
                                          alternativa[index].id !== null
                                            ? theme.cor.cor_principal_500
                                            : theme.cor.cor_branco_100,
                                        color:
                                          selecao === index ||
                                          alternativa[index].id !== null
                                            ? theme.cor.cor_branco_200
                                            : theme.cor.cor_preto_300,
                                        fontFamily: theme.fonte.font.fonte_1,
                                      }}
                                      variant={"contained"}
                                      size="small"
                                      disabled={
                                        mostrarProximo === 1 ? false : true
                                      }
                                    >
                                      {atividade?.tipo === "quiz"
                                        ? aula.tipo === "avaliacao_quiz"
                                          ? "Quiz"
                                          : "Quiz " + index
                                        : "Questão " + index}
                                    </Button>
                                  )}
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{ marginLeft: "2rem", marginRight: "2rem" }}
                        >
                          <LinearProgress
                            sx={{
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: theme.cor.cor_principal_500,
                                borderRadius: "1rem",
                              },
                              height: 5,
                              borderRadius: 5,
                              background: theme.cor.cor_branco_300,
                            }}
                            variant="determinate"
                            value={
                              (100 / aula?.atividades?.length) * (selecao + 1)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {selecao < aula?.atividades?.length - 1 ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {aula?.anexo?.length > 0 && selecao === 0 ? (
                          <Grid item xs={10}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              style={{
                                background: theme.cor.cor_branco_100,
                                padding: "1rem",
                                borderRadius: "1rem",
                                marginTop: "2rem",
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                color: theme.cor.cor_preto_300,
                                fontFamily: theme.fonte.font.fonte_1,
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                style={{
                                  fontWeight: theme.fonte.bold.semi_bold,
                                }}
                              >
                                Anexos:
                              </Grid>
                              {aula?.anexo.map((e: any, i: any) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e?.id}
                                    paddingLeft={"1rem"}
                                  >
                                    {i + 1}:{" "}
                                    <Link
                                      href={`https://drive.google.com/uc?export=download&id=${e?.id}&confirm=yes`}
                                    >
                                      {e?.nome}
                                    </Link>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={6}
                          margin={"1rem"}
                          marginTop={"2rem"}
                          style={{
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            marginBottom: 0,
                          }}
                        >
                          {selecao === 0 ||
                          validarLink(aula.atividades[selecao]?.pergunta) ? (
                            <iframe
                              ref={(el) => {
                                if (selecao === 0) {
                                  iframeRef.current = el;
                                }
                              }}
                              style={{
                                border: 0,
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                borderRadius: "1rem",
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                fontWeight: theme.fonte.bold.medio,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                aspectRatio: "16 / 9",
                                width: "100%",
                                height: "auto",
                              }}
                              allow="fullscreen"
                              src={
                                selecao === 0
                                  ? aula.link
                                  : aula?.atividades[selecao]?.pergunta
                              }
                            />
                          ) : (
                            <div
                              style={{
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                fontWeight: theme.fonte.bold.medio,
                                borderRadius: "1rem",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                paddingLeft: "2rem",
                                paddingRight: "2rem",
                                border: `dashed  ${theme.cor.cor_principal_500} 1px`,
                                textAlign: "center",
                              }}
                            >
                              {aula?.atividades[selecao]?.pergunta}
                            </div>
                          )}
                        </Grid>
                        {selecao === 0 ? (
                          ""
                        ) : (
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                paddingTop={"2rem"}
                              >
                                <Grid item xs={10}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="stretch"
                                    spacing={2}
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    {aula?.atividades[
                                      selecao
                                    ]?.alternativas?.map(
                                      (r: any, opcaoIndex: any) => (
                                        <Grid
                                          item
                                          key={opcaoIndex}
                                          xs={
                                            aula?.atividades[selecao]
                                              ?.alternativas?.length %
                                              2 !==
                                              0 &&
                                            opcaoIndex ===
                                              aula.atividades[selecao]
                                                ?.alternativas?.length -
                                                1
                                              ? 10
                                              : 5
                                          }
                                        >
                                          <Button
                                            style={{
                                              background:
                                                alternativa[selecao].id === null
                                                  ? theme.cor.cor_principal_200
                                                  : alternativa[selecao].id ===
                                                    r.id
                                                  ? theme.cor.cor_principal_500
                                                  : theme.cor.cor_principal_200,
                                              color: theme.cor.cor_branco_200,
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              display: "flex",
                                              justifyContent: "center",
                                              width: "100%",

                                              height: "100%",
                                            }}
                                            onClick={(e) =>
                                              setalternativa((ant: any) => {
                                                let novaalternativa = [...ant];
                                                if (
                                                  novaalternativa[selecao]
                                                    .id === null
                                                ) {
                                                  novaalternativa[selecao].id =
                                                    r.id;
                                                } else if (
                                                  novaalternativa[selecao]
                                                    .id === r.id
                                                ) {
                                                  novaalternativa[selecao].id =
                                                    null;
                                                } else if (
                                                  novaalternativa[selecao]
                                                    .id !== r.id
                                                ) {
                                                  novaalternativa[selecao].id =
                                                    r.id;
                                                } else {
                                                  novaalternativa[selecao].id =
                                                    -1;
                                                }
                                                novaalternativa[
                                                  selecao
                                                ].id_alternativa =
                                                  r.id_atividade;
                                                return novaalternativa;
                                              })
                                            }
                                          >
                                            <p
                                              style={{
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              {r.nome}
                                            </p>
                                          </Button>
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          paddingBottom={"1rem"}
                          width={"100%"}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            width={"100%"}
                            padding={"2rem"}
                            marginTop={".5rem"}
                            paddingRight={"1rem"}
                          >
                            <Grid item>
                              <Button
                                onClick={(_) =>
                                  aula?.atividades?.filter(
                                    (item: any) => item?.id !== -1
                                  )?.length === 0
                                    ? setEnviar(2)
                                    : SetSelecao(selecao + 1)
                                }
                                endIcon={<SkipNextIcon />}
                                style={{
                                  marginRight: "1rem",
                                  background: theme.cor.cor_principal_500,
                                  color: theme.cor.cor_branco_200,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                variant={"contained"}
                                size="small"
                                disabled={mostrarProximo === 1 ? false : true}
                              >
                                Próximo
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          item
                          xs={12}
                          paddingBottom={"1rem"}
                          margin={"1rem"}
                          marginTop={"2rem"}
                          style={{
                            marginLeft: "3rem",
                            marginBottom: 0,
                            paddingRight: "1rem",
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            style={{
                              background: theme.cor.cor_branco_100,
                              paddingBottom: "1rem",
                              borderRadius: "1rem",
                              boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            }}
                          >
                            {aula?.atividades?.map(
                              (atividade: any, index: any) => {
                                if (atividade.id !== -1) {
                                  return (
                                    <Grid item key={index}>
                                      <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <p
                                            style={{
                                              fontWeight:
                                                theme.fonte.bold.semi_bold,
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              color: theme.cor.cor_preto_300,
                                            }}
                                          >
                                            {atividade?.tipo === "quiz"
                                              ? aula.tipo === "avaliacao_quiz"
                                                ? "Quiz"
                                                : "Quiz " + index
                                              : "Questão " + index}
                                          </p>
                                        </Grid>
                                        <Grid item>
                                          <span
                                            style={{
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              color: theme.cor.cor_preto_300,
                                            }}
                                          >
                                            {atividade?.alternativas?.filter(
                                              (e: any) =>
                                                e.id === alternativa[index].id
                                            ).length !== 0
                                              ? atividade?.alternativas?.filter(
                                                  (e: any) =>
                                                    e.id ===
                                                    alternativa[index].id
                                                )[0].nome
                                              : "Nenhuma seleção"}
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              }
                            )}

                            {/* {aula?.atividades?.filter(
                              (item: any) => item?.id !== -1
                            )?.length === 0 ? (
                              <>
                                <h1
                                  style={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                  }}
                                >
                                  Aula finalizada
                                </h1>
                              </>
                            ) : null} */}

                            <Grid item>
                              <Button
                                style={{
                                  fontFamily: theme.fonte.font.fonte_1,
                                  color: theme.cor.cor_branco_100,
                                  background:
                                    theme.cor.cor_verde_secundaria_500,
                                  marginTop: "1rem",
                                }}
                                variant="contained"
                                size="small"
                                endIcon={<SkipNextIcon />}
                                onClick={() => {
                                  setOpen(true);
                                }}
                              >
                                Finalizar
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <Resumo
                    decodedToken={decodedToken}
                    aulaItem={aula}
                    alternativasItem={alternativa}
                  />
                )}
              </Grid>
            </Grid>
            {enviar !== 2 ? (
              <>
                <Grid item style={{ background: "red" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    height={"100%"}
                  >
                    <Grid item>
                      {selecao === aula?.atividades?.length - 1 ? (
                        <div style={{ position: "relative" }}>
                          <img
                            style={{
                              position: "absolute",
                              right: 0,
                              bottom: 0,
                              maxHeight: "calc(20vh)",
                            }}
                            src={AlternativaFoto}
                            alt="Verificar alternativas"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Aula;
