import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import {StyledTableTurma} from "../theme";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import {CarregarLoading} from "../carregar";
import {useState} from "react";
import api from "../axios/axios";
import Deletar from "../confirmar/deletar";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const schemaTurma = yup
  .object()
  .shape({
    nome: yup.string().required("Por favor, preencha este campo."),
    matricula: yup.string().nullable(),
  })
  .required();

const schemaCadastro = yup
  .object()
  .shape({
    nome: yup.string().required("Por favor, preencha este campo."),
    sobrenome: yup.string().required("Por favor, preencha este campo."),
    matricula: yup.string().required("Por favor, preencha este campo."),
    tipo: yup.string().required("Por favor, preencha este campo."),
    email: yup.string().required("Por favor, preencha este campo."),
    senha1: yup
      .string()
      .oneOf([yup.ref("senha2"), ""], "As senhas precisam ser iguais.")
      .required("Por favor, preencha este campo."),
    senha2: yup
      .string()
      .oneOf([yup.ref("senha1"), ""], "As senhas precisam ser iguais.")
      .required("Por favor, preencha este campo."),
  })
  .required();

const CriarNovaTurma = ({
  theme,
  setConfiguracao,
  configuracao,
  escolaSelecao,
  escola,
  setEscolaSelecao,
  setCidadeSelecao,
  cidade,
  setTurmaSelecao,
  cidadeSelecao,
  pessoa,
  setPessoas,
  turmaSelecao,
  setTurma,
}: any) => {
  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      nome: configuracao?.editar ? turmaSelecao?.nome : "",
    },
    resolver: yupResolver(schemaTurma),
  });

  const {
    formState: formState2,
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
    setValue: setValue2,
    watch: watch2,
  } = useForm({
    defaultValues: {
      nome: "",
      sobrenome: "",
      matricula: "",
      email: "",
      tipo: "aluno",
      senha1: "",
      senha2: "",
    },
    resolver: yupResolver(schemaCadastro),
  });

  const [pessoaSelecao, setPessoaSelecao] = useState<any>({ id: -1 });
  const [pessoas, setPessoa] = useState<any>(
    configuracao?.criar ? { items: [] } : pessoa
  );
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    data.pessoas = pessoas;
    if (configuracao?.criar) {
      toast.info("Criando turma");
      api
        .post("/turma/" + escolaSelecao?.id, data)
        .then((r: any) => {
          setTurma((antes: any) => {
            const newItems = [...antes?.items, r.data];

            newItems.sort((a, b) => a.nome.localeCompare(b.nome));

            return {
              ...antes,
              items: newItems,
            };
          });
          setTurmaSelecao(r.data);
          toast.success("Turma criada");
          setConfiguracao((antes: any) => {
            return { ...antes, criar: false, editar: false };
          });
        })
        .catch(() => toast.error("Turma não criada"));
    } else {
      toast.info("Editando turma");
      api
        .patch("/turma/" + turmaSelecao?.id, data)
        .then((r: any) => {
          setTurma((antes: any) => {
            return {
              ...antes,
              items: antes?.items.map((e: any) => {
                if (e?.id === turmaSelecao?.id) {
                  e.nome = data.nome;
                  return e;
                } else {
                  return e;
                }
              }),
            };
          });
          toast.success("Turma editada");
          setPessoas(pessoas);
          setConfiguracao((antes: any) => {
            return { ...antes, criar: false, editar: false };
          });
        })
        .catch(() => toast.error("Turma não editada"));
    }
  };

  const cadastro = (data: any) => {
    toast.info("Cadastrando pessoa");
    api
      .post("/cadastro", data)
      .then((r: any) => {
        setPessoa(() => {
          return {
            ...pessoas,
            items: [
              {
                nome: r?.data?.nome,
                id_keycloak: r?.data?.id_keycloak,
                nova: true,
              },
              ...pessoas?.items,
            ],
          };
        });
        reset2();
      })
      .catch(() => toast.error("Pessoa não cadastrada"));
  };

  const funcaoDeletar = () => {
    toast.info("Deletando pessoa");
    api
      .delete(`pessoa/${turmaSelecao?.id}/${pessoaSelecao.id_keycloak}`)
      .then(() => {
        setPessoa((antes: any) => {
          return {
            ...antes,
            items: antes?.items.filter(
              (e: any) => e.id_keycloak !== pessoaSelecao.id_keycloak
            ),
          };
        });
        toast.success("Pessoa deletada");
      })
      .catch(() => {
        toast.error("Pessoa não deletada");
      });
  };

  return (
    <>
      <Deletar
        open={open}
        handleClose={handleClose}
        item={pessoaSelecao}
        funcaoDeletar={funcaoDeletar}
      />
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            <Button
              style={{
                background: theme.cor.cor_branco_100,
                color: theme.cor.cor_preto_300,
                fontFamily: theme.fonte.font.fonte_1,
              }}
              endIcon={<ChevronLeftIcon style={{ marginTop: "-.1rem" }} />}
              variant={"contained"}
              size="small"
              onClick={() =>
                setConfiguracao((antes: any) => {
                  return { ...antes, criar: false, editar: false };
                })
              }
            >
              voltar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={"1.5rem"} paddingBottom={"1rem"}>
        <Grid
          item
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
            borderRadius: "1rem",
            background: theme.cor.cor_branco_100,
            paddingBottom: "1rem",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              background: theme.cor.cor_principal_500,
              color: theme.cor.cor_branco_100,
              fontFamily: theme.fonte.font.fonte_1,
              fontSize: "18px",
              fontWeight: theme.fonte.bold.medio,
              padding: ".5rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
            }}
          >
            <Grid item style={{ paddingLeft: "1rem" }}>
              CRIAR NOVA TURMA
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    style={{
                      color: theme.cor.cor_branco_100,
                      fontFamily: theme.fonte.font.fonte_1,
                      fontWeight: theme.fonte.bold.medio,
                      background: theme.cor.cor_principal_400,
                    }}
                    size="small"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={configuracao?.carregar}
                    endIcon={
                      <SaveIcon
                        style={{
                          fill: theme.cor.cor_branco_100,
                          marginTop: "-.1rem",
                        }}
                      />
                    }
                  >
                    {configuracao?.criar ? "Criar nova turma" : "Salvar turma"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            paddingTop={"1.5rem"}
          >
            <Grid item sx={{ maxWidth: "100%" }}>
              <FormControl sx={{ maxWidth: "100%" }} fullWidth size="small">
                <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                  Cidade
                </InputLabel>
                <Select
                  id="cidade"
                  // disabled={buscar !== "" ? true : false}
                  value={cidadeSelecao.id}
                  label="Cidade"
                  style={{
                    color: theme.cor.cor_preto_300,
                    height: "33px",
                    fontFamily: theme.fonte.font.fonte_1,
                  }}
                  // {...register("tipo")}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.cor.cor_preto_100,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.cor.cor_principal_500,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.cor.cor_preto_100,
                    },

                    color: theme.cor.cor_preto_300,
                    fontFamily: theme.fonte.font.fonte_1,
                  }}
                >
                  {cidade?.items?.length > 0 ? (
                    cidade?.items?.map((c: any) => (
                      <MenuItem
                        onClick={() => setCidadeSelecao(c)}
                        sx={{
                          color: theme.cor.cor_preto_300,
                          fontFamily: theme.fonte.font.fonte_1,
                          "&:hover": {
                            background: theme.cor.cor_principal_300,
                            color: theme.cor.cor_branco_100,
                          },
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          "&.Mui-selected": {
                            background: theme.cor.cor_principal_200,
                            color: theme.cor.cor_branco_100,
                          },
                        }}
                        key={c.id}
                        value={c.id}
                      >
                        {c.nome}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      sx={{
                        color: theme.cor.cor_preto_300,
                        fontFamily: theme.fonte.font.fonte_1,
                        "&:hover": {
                          background: theme.cor.cor_principal_300,
                          color: theme.cor.cor_branco_100,
                        },
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        "&.Mui-selected": {
                          background: theme.cor.cor_principal_200,
                          color: theme.cor.cor_branco_100,
                        },
                      }}
                      key={-1}
                      value={-1}
                    >
                      Nenhuma cidade
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ maxWidth: "100%" }}>
              <FormControl sx={{ maxWidth: "100%" }} fullWidth size="small">
                <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                  Escola
                </InputLabel>
                <Select
                  id="escola"
                  // disabled={buscar !== "" ? true : false}
                  value={escolaSelecao.id}
                  label="Escola"
                  style={{
                    color: theme.cor.cor_preto_300,
                    height: "33px",
                    fontFamily: theme.fonte.font.fonte_1,
                  }}
                  // {...register("tipo")}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.cor.cor_preto_100,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.cor.cor_principal_500,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.cor.cor_preto_100,
                    },

                    color: theme.cor.cor_preto_300,
                    fontFamily: theme.fonte.font.fonte_1,
                  }}
                >
                  {escola?.items?.length > 0 ? (
                    escola?.items?.map((c: any) => (
                      <MenuItem
                        onClick={() => setEscolaSelecao(c)}
                        sx={{
                          color: theme.cor.cor_preto_300,
                          fontFamily: theme.fonte.font.fonte_1,
                          "&:hover": {
                            background: theme.cor.cor_principal_300,
                            color: theme.cor.cor_branco_100,
                          },
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          "&.Mui-selected": {
                            background: theme.cor.cor_principal_200,
                            color: theme.cor.cor_branco_100,
                          },
                        }}
                        key={c.id}
                        value={c.id}
                      >
                        {c.nome}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      sx={{
                        color: theme.cor.cor_preto_300,
                        fontFamily: theme.fonte.font.fonte_1,
                        "&:hover": {
                          background: theme.cor.cor_principal_300,
                          color: theme.cor.cor_branco_100,
                        },
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        "&.Mui-selected": {
                          background: theme.cor.cor_principal_200,
                          color: theme.cor.cor_branco_100,
                        },
                      }}
                      key={-2}
                      value={-1}
                    >
                      Nenhuma escola
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              paddingTop={"2rem"}
            >
              <Grid item xs={10} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  sx={{
                    ".MuiOutlinedInput-input": {
                      color: theme.cor.cor_preto_300,
                    },
                    "& label": {
                      color: theme.cor.cor_preto_300,
                    },
                    "& label.Mui-focused": {
                      color: theme.cor.cor_principal_500,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: theme.cor.cor_principal_500,
                      },
                      "& fieldset": {
                        borderColor: theme.cor.cor_preto_100,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.cor.cor_principal_500,
                      },
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                  }}
                  label={"Turma"}
                  required
                  {...register("nome")}
                  error={Boolean(formState.errors?.nome)}
                  helperText={formState?.errors?.nome?.message}
                  type="text"
                ></TextField>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="space-between"
              spacing={2}
              paddingTop={"3rem"}
            >
              <Grid item>
                <TextField
                  fullWidth
                  size="small"
                  sx={{
                    ".MuiOutlinedInput-input": {
                      color: theme.cor.cor_preto_300,
                    },
                    "& label": {
                      color: theme.cor.cor_preto_300,
                    },
                    "& label.Mui-focused": {
                      color: theme.cor.cor_principal_500,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: theme.cor.cor_principal_500,
                      },
                      "& fieldset": {
                        borderColor: theme.cor.cor_preto_100,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.cor.cor_principal_500,
                      },
                    },
                  }}
                  {...register("matricula")}
                  label={"N° Matrícula"}
                  type="text"
                />
              </Grid>

              <Grid item>
                <Button
                  style={{
                    color: theme.cor.cor_branco_100,
                    fontFamily: theme.fonte.font.fonte_1,
                    fontWeight: theme.fonte.bold.medio,
                    background: theme.cor.cor_verde_500,
                    marginTop: ".3rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  onClick={() => {
                    if (
                      getValues()?.matricula &&
                      pessoas?.items.filter(
                        (e: any) => e.id_keycloak === getValues()?.matricula
                      ).length === 0
                    ) {
                      if (configuracao?.criar) {
                        api
                          .get(`pessoa/${getValues()?.matricula}`)
                          .then((r) => {
                            if (r.data === true) {
                              toast.info("Matrícula já existe");
                              return;
                            }
                            setPessoa(() => {
                              return {
                                ...pessoas,
                                items: [
                                  {
                                    nome: r?.data?.nome,
                                    id_keycloak: r?.data?.id_keycloak,
                                    nova: true,
                                  },
                                  ...pessoas?.items,
                                ],
                              };
                            });
                            setValue(`matricula`, null);
                          })
                          .catch((e) =>
                            toast.error("Matrícula não encontrada")
                          );
                      } else {
                        api
                          .get(
                            `pessoa/${turmaSelecao?.id}/${
                              getValues()?.matricula
                            }`
                          )
                          .then((r) => {
                            if (r.data === true) {
                              toast.error("Matrícula já existe");
                              return;
                            }
                            setPessoa(() => {
                              return {
                                ...pessoas,
                                items: [
                                  {
                                    nome: r?.data?.nome,
                                    nova: true,
                                    id_keycloak: r?.data?.id_keycloak,
                                  },
                                  ...pessoas?.items,
                                ],
                              };
                            });
                            setValue(`matricula`, null);
                          })
                          .catch((e) =>
                            toast.error("Matrícula não encontrada")
                          );
                      }
                    } else {
                      if (getValues()?.matricula !== "")
                        toast.error("Matrícula já existe");
                    }
                  }}
                  size="small"
                  endIcon={
                    <CheckCircleOutlineIcon
                      style={{
                        fill: theme.cor.cor_branco_100,
                        marginTop: "-.1rem",
                      }}
                    />
                  }
                >
                  incluir na lista
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} style={{ marginTop: "1.5rem" }}>
            <p
              style={{
                textAlign: "center",
                padding: 0,
                marginBottom: ".5rem",
                fontFamily: theme.fonte.font.fonte_1,
              }}
            >
              Insira um aluno existente
            </p>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={10} sm={4}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
            <p
              style={{
                textAlign: "center",
                padding: 0,
                marginTop: ".6rem",
                fontFamily: theme.fonte.font.fonte_1,
              }}
            >
              Ou cadastre um novo
            </p>
          </Grid>
          <form onSubmit={handleSubmit2(cadastro)}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={10} sm={2}>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      ".MuiOutlinedInput-input": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label.Mui-focused": {
                        color: theme.cor.cor_principal_500,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "& fieldset": {
                          borderColor: theme.cor.cor_preto_100,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                      },
                    }}
                    {...register2("nome")}
                    required
                    error={Boolean(formState2.errors?.nome)}
                    helperText={formState2?.errors?.nome?.message}
                    label={"Nome"}
                    type="text"
                  />
                </Grid>
                <Grid item xs={10} sm={2}>
                  <TextField
                    fullWidth
                    size="small"
                    sx={{
                      ".MuiOutlinedInput-input": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label.Mui-focused": {
                        color: theme.cor.cor_principal_500,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "& fieldset": {
                          borderColor: theme.cor.cor_preto_100,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                      },
                    }}
                    {...register2("sobrenome")}
                    error={Boolean(formState2.errors?.sobrenome)}
                    helperText={formState2?.errors?.sobrenome?.message}
                    label={"Sobrenome"}
                    required
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={10} sm={4}>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      ".MuiOutlinedInput-input": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label.Mui-focused": {
                        color: theme.cor.cor_principal_500,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "& fieldset": {
                          borderColor: theme.cor.cor_preto_100,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                      },
                    }}
                    {...register2("matricula")}
                    error={Boolean(formState2.errors?.matricula)}
                    helperText={formState2?.errors?.matricula?.message}
                    required
                    label={"Matrícula"}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={10} sm={4} sx={{ maxWidth: "100%" }}>
                  <FormControl sx={{ maxWidth: "100%" }} fullWidth size="small">
                    <InputLabel
                      required
                      style={{ color: theme.cor.cor_preto_500 }}
                    >
                      Tipo
                    </InputLabel>
                    <Select
                      id="tipo"
                      label="tipo"
                      defaultValue={"aluno"}
                      style={{
                        color: theme.cor.cor_preto_300,
                        height: "33px",
                        fontFamily: theme.fonte.font.fonte_1,
                      }}
                      required
                      {...register2("tipo")}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.cor.cor_preto_100,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.cor.cor_preto_100,
                        },

                        color: theme.cor.cor_preto_300,
                        fontFamily: theme.fonte.font.fonte_1,
                      }}
                    >
                      <MenuItem
                        sx={{
                          color: theme.cor.cor_preto_300,
                          fontFamily: theme.fonte.font.fonte_1,
                          "&:hover": {
                            background: theme.cor.cor_principal_300,
                            color: theme.cor.cor_branco_100,
                          },
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          "&.Mui-selected": {
                            background: theme.cor.cor_principal_200,
                            color: theme.cor.cor_branco_100,
                          },
                        }}
                        key={"aluno"}
                        value={"aluno"}
                      >
                        Aluno
                      </MenuItem>
                      <MenuItem
                        sx={{
                          color: theme.cor.cor_preto_300,
                          fontFamily: theme.fonte.font.fonte_1,
                          "&:hover": {
                            background: theme.cor.cor_principal_300,
                            color: theme.cor.cor_branco_100,
                          },
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          "&.Mui-selected": {
                            background: theme.cor.cor_principal_200,
                            color: theme.cor.cor_branco_100,
                          },
                        }}
                        key={"professor"}
                        value={"professor"}
                      >
                        Professor
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={10} sm={4}>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      ".MuiOutlinedInput-input": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label.Mui-focused": {
                        color: theme.cor.cor_principal_500,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "& fieldset": {
                          borderColor: theme.cor.cor_preto_100,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                      },
                    }}
                    {...register2("email")}
                    error={Boolean(formState2.errors?.email)}
                    helperText={formState2?.errors?.email?.message}
                    required
                    label={"Email"}
                    type="text"
                  />
                </Grid>

                <Grid xs={12} marginTop={".5rem"}></Grid>
                <Grid item xs={10} sm={2}>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      ".MuiOutlinedInput-input": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label.Mui-focused": {
                        color: theme.cor.cor_principal_500,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "& fieldset": {
                          borderColor: theme.cor.cor_preto_100,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                      },
                    }}
                    {...register2("senha1")}
                    required
                    error={Boolean(
                      formState2.errors?.senha2 || formState2.errors?.senha1
                    )}
                    helperText={
                      formState2?.errors?.senha2?.message ||
                      formState2?.errors?.senha1?.message
                    }
                    label={"Senha"}
                    type="password"
                  />
                </Grid>
                <Grid item xs={10} sm={2}>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      ".MuiOutlinedInput-input": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label": {
                        color: theme.cor.cor_preto_300,
                      },
                      "& label.Mui-focused": {
                        color: theme.cor.cor_principal_500,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "& fieldset": {
                          borderColor: theme.cor.cor_preto_100,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                      },
                    }}
                    {...register2("senha2")}
                    required
                    error={Boolean(
                      formState2.errors?.senha2 || formState2.errors?.senha1
                    )}
                    helperText={
                      formState2?.errors?.senha2?.message ||
                      formState2?.errors?.senha1?.message
                    }
                    label={"Confirmar senha"}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item>
                  <Button
                    style={{
                      color: theme.cor.cor_branco_100,
                      fontFamily: theme.fonte.font.fonte_1,
                      fontWeight: theme.fonte.bold.medio,
                      background: theme.cor.cor_verde_500,
                      marginTop: ".3rem",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                    size="small"
                    type="submit"
                    endIcon={
                      <GroupAddIcon
                        style={{
                          fill: theme.cor.cor_branco_100,
                          marginTop: "-.1rem",
                        }}
                      />
                    }
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          {configuracao?.carregar ? (
            <CarregarLoading />
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="space-between"
              padding={"2rem"}
              paddingTop={"1.5rem"}
              paddingBottom={0}
            >
              <StyledTableTurma>
                <TableBody
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                    borderRadius: "1rem",
                  }}
                >
                  {pessoas?.items?.length > 0
                    ? pessoas?.items?.map((p: any, i: number) => (
                        <TableRow
                          key={p.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            background: theme.cor.cor_branco_200,
                          }}
                        >
                          <TableCell
                            align="left"
                            style={{
                              borderBottom:
                                pessoas?.items?.length - 1 !== i
                                  ? "1px solid" + theme.cor.cor_branco_500
                                  : "",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.medio,
                                fontSize: "14px",
                                fontFamily: theme.fonte.font.normal,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingLeft: "1rem",
                              }}
                            >
                              {p.nome}
                            </p>
                          </TableCell>
                          <TableCell
                            style={{
                              borderLeft:
                                "1px solid" + theme.cor.cor_branco_500,

                              borderBottom:
                                pessoas?.items?.length - 1 !== i
                                  ? "1px solid" + theme.cor.cor_branco_500
                                  : "",
                            }}
                            align="center"
                          >
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.medio,
                                fontSize: "14px",
                                fontFamily: theme.fonte.font.normal,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {p.id_keycloak}
                            </p>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "30px",
                              borderBottom:
                                pessoas?.items?.length - 1 !== i
                                  ? "1px solid" + theme.cor.cor_branco_500
                                  : "",
                            }}
                            align="center"
                          >
                            <DeleteIcon
                              style={{
                                height: "20px",
                                marginTop: ".3rem",
                                fill: theme.cor.cor_preto_300,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (configuracao?.criar) {
                                  setPessoa((antes: any) => {
                                    return {
                                      ...antes,
                                      items: antes?.items.filter(
                                        (e: any) =>
                                          e.id_keycloak !== p.id_keycloak
                                      ),
                                    };
                                  });
                                } else {
                                  setPessoaSelecao(p);
                                  setOpen(true);
                                }
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : ""}
                </TableBody>
              </StyledTableTurma>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CriarNovaTurma;
