import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useTheme} from "styled-components";
import api from "../axios/axios";
import CardMenu, {MenuPhone} from "../menu";
import MenuSuperior from "../menuSuperior";
import {mobile, StyledResultAntd, StyledTable} from "../theme";
import CriarNovaTurma from "./criar_nova";
import Paginacao from "../paginacao";
import {CarregarLoading} from "../carregar";
import DeleteIcon from "@mui/icons-material/Delete";
import Deletar from "../confirmar/deletar";
import {toast} from "react-toastify";

const Turmas = ({ decodedToken }: any) => {
  const theme = useTheme();
  const [cidade, setCidade] = useState<any>({ total: 0, items: [] });
  const [cidadeSelecao, setCidadeSelecao] = useState<any>({ id: -1 });
  const [escola, setEscola] = useState<any>({ total: 0, items: [] });
  const [escolaSelecao, setEscolaSelecao] = useState<any>({ id: -1 });
  const [turma, setTurma] = useState<any>({ total: 0, items: [] });
  const [turmaSelecao, setTurmaSelecao] = useState<any>({ id: -1 });
  const [pessoas, setPessoas] = useState<any>({ total: 0, items: [] });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [configuracao, setConfiguracao] = useState({
    carregar: true,
    criar: false,
    editar: false,
  });

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    api
      .get("cidade")
      .then((r) => {
        setCidade(r.data);
        if (r?.data?.items?.length > 0) setCidadeSelecao(r.data.items[0]);
        else
          setConfiguracao((antes: any) => {
            return { ...antes, carregar: false };
          });
      })
      .catch(() =>
        setConfiguracao((antes: any) => {
          return { ...antes, carregar: false };
        })
      );
  }, []);

  useEffect(() => {
    if (cidadeSelecao.id !== -1) {
      setConfiguracao((antes: any) => {
        return { ...antes, carregar: true };
      });
      setTurma({ total: 0, items: [] });
      setEscola({ total: 0, items: [] });
      setEscolaSelecao({ id: -1 });
      setTurmaSelecao({ id: -1 });
      setPessoas({ total: 0, items: [] });
      api
        .get("cidade/escola/" + cidadeSelecao.id)
        .then((r) => {
          setEscola(r.data);
          if (r?.data?.items?.length > 0) setEscolaSelecao(r.data.items[0]);
          else
            setConfiguracao((antes: any) => {
              return { ...antes, carregar: false };
            });
        })
        .catch(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregar: false };
          })
        );
    }
  }, [cidadeSelecao]);

  useEffect(() => {
    if (escolaSelecao.id !== -1) {
      setConfiguracao((antes: any) => {
        return { ...antes, carregar: true };
      });
      setPessoas({ total: 0, items: [] });

      setTurma({ total: 0, items: [] });
      setTurmaSelecao({ id: -1 });

      api
        .get("escola/turma/" + escolaSelecao?.id)
        .then((r) => {
          setTurma(r.data);
          if (r?.data?.items?.length > 0) setTurmaSelecao(r.data.items[0]);
          else
            setConfiguracao((antes: any) => {
              return { ...antes, carregar: false };
            });
        })
        .catch(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregar: false };
          })
        );
    }
  }, [escolaSelecao]);

  useEffect(() => {
    if (turmaSelecao.id !== -1) {
      setConfiguracao((antes: any) => {
        return { ...antes, carregar: true };
      });
      setPessoas({ total: 0, items: [] });
      api
        .get("turma/pessoas/" + turmaSelecao?.id)
        .then((r) => {
          setPessoas(r.data);
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregar: false };
          })
        )
        .catch(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregar: false };
          })
        );
    }
  }, [turmaSelecao]);

  const handlePage = (page: any) => {
    setConfiguracao((antes: any) => {
      return { ...antes, carregar: true };
    });
    setPessoas({ total: 0, items: [] });
    api
      .get("turma/pessoas/" + turmaSelecao?.id + "?&page=" + page)
      .then((r) => {
        setPessoas(r.data);
      })
      .finally(() =>
        setConfiguracao((antes: any) => {
          return { ...antes, carregar: false };
        })
      );
    return;
  };

  const funcaoDeletar = () => {
    toast.info("Deletando turma");
    api
      .delete("turma/" + turmaSelecao.id)
      .then(() => {
        setPessoas({ total: 0, items: [] });
        setTurma((antesTurma: any) => {
          const turmas = antesTurma.items.filter(
            (t: any) => t?.id !== turmaSelecao?.id
          );
          if (turmas?.length > 0) {
            setTurmaSelecao(turmas[0]);
          } else {
            setTurmaSelecao({ id: -1 });
          }
          const novaTurmas = { ...antesTurma, items: turmas };
          return novaTurmas;
        });
        toast.success("Turma deletada");
      })
      .catch(() => {
        toast.error("Turma não deletada");
      });
  };

  return (
    <>
      <Deletar
        open={open}
        handleClose={handleClose}
        item={turmaSelecao}
        funcaoDeletar={funcaoDeletar}
      />
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={14}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            ativo={mobile(windowSize) ? true : false}
            decodedToken={decodedToken}
          />
          <Grid
            item
            style={{
              background: theme.cor.cor_branco_100,
              padding: 0,
              margin: 0,
            }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Turmas"} />
          </Grid>
          <Grid
            item
            padding={3}
            marginTop={mobile(windowSize) ? "" : "4rem"}
            paddingRight={"1em"}
          >
            {configuracao?.criar || configuracao?.editar ? (
              <CriarNovaTurma
                theme={theme}
                setConfiguracao={setConfiguracao}
                configuracao={configuracao}
                escolaSelecao={escolaSelecao}
                escola={escola}
                setEscolaSelecao={setEscolaSelecao}
                cidade={cidade}
                setTurmaSelecao={setTurmaSelecao}
                turmaSelecao={turmaSelecao}
                setCidadeSelecao={setCidadeSelecao}
                cidadeSelecao={cidadeSelecao}
                setPessoas={setPessoas}
                pessoa={pessoas}
                setTurma={setTurma}
              />
            ) : (
              <>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item sx={{ maxWidth: "100%" }}>
                      <FormControl
                        sx={{ maxWidth: "100%" }}
                        fullWidth
                        size="small"
                      >
                        <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                          Cidade
                        </InputLabel>
                        <Select
                          id="cidade"
                          // disabled={buscar !== "" ? true : false}
                          value={cidadeSelecao.id}
                          label="Cidade"
                          style={{
                            color: theme.cor.cor_preto_300,
                            height: "33px",
                            fontFamily: theme.fonte.font.fonte_1,
                          }}
                          // {...register("tipo")}
                          sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_preto_100,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_principal_500,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_preto_100,
                            },

                            color: theme.cor.cor_preto_300,
                            fontFamily: theme.fonte.font.fonte_1,
                          }}
                        >
                          {cidade?.items?.length > 0 ? (
                            cidade?.items?.map((c: any) => (
                              <MenuItem
                                onClick={() => setCidadeSelecao(c)}
                                sx={{
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                  "&:hover": {
                                    background: theme.cor.cor_principal_300,
                                    color: theme.cor.cor_branco_100,
                                  },
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                  "&.Mui-selected": {
                                    background: theme.cor.cor_principal_200,
                                    color: theme.cor.cor_branco_100,
                                  },
                                }}
                                key={c.id}
                                value={c.id}
                              >
                                {c.nome}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              sx={{
                                color: theme.cor.cor_preto_300,
                                fontFamily: theme.fonte.font.fonte_1,
                                "&:hover": {
                                  background: theme.cor.cor_principal_300,
                                  color: theme.cor.cor_branco_100,
                                },
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                "&.Mui-selected": {
                                  background: theme.cor.cor_principal_200,
                                  color: theme.cor.cor_branco_100,
                                },
                              }}
                              key={-1}
                              value={-1}
                            >
                              Nenhuma cidade
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sx={{ maxWidth: "100%" }}>
                      <FormControl
                        sx={{ maxWidth: "100%" }}
                        fullWidth
                        size="small"
                      >
                        <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                          Escola
                        </InputLabel>
                        <Select
                          id="escola"
                          // disabled={buscar !== "" ? true : false}
                          value={escolaSelecao.id}
                          label="Escola"
                          style={{
                            color: theme.cor.cor_preto_300,
                            height: "33px",
                            fontFamily: theme.fonte.font.fonte_1,
                          }}
                          // {...register("tipo")}
                          sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_preto_100,
                            },
                            display: "flex",
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_principal_500,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_preto_100,
                            },

                            color: theme.cor.cor_preto_300,
                            fontFamily: theme.fonte.font.fonte_1,
                          }}
                        >
                          {escola?.items?.length > 0 ? (
                            escola?.items?.map((c: any) => (
                              <MenuItem
                                onClick={() => setEscolaSelecao(c)}
                                sx={{
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                  "&:hover": {
                                    background: theme.cor.cor_principal_300,
                                    color: theme.cor.cor_branco_100,
                                  },
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                  "&.Mui-selected": {
                                    background: theme.cor.cor_principal_200,
                                    color: theme.cor.cor_branco_100,
                                  },
                                }}
                                key={c.id}
                                value={c.id}
                              >
                                {c.nome}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              sx={{
                                color: theme.cor.cor_preto_300,
                                fontFamily: theme.fonte.font.fonte_1,
                                "&:hover": {
                                  background: theme.cor.cor_principal_300,
                                  color: theme.cor.cor_branco_100,
                                },
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                "&.Mui-selected": {
                                  background: theme.cor.cor_principal_200,
                                  color: theme.cor.cor_branco_100,
                                },
                              }}
                              key={-2}
                              value={-1}
                            >
                              Nenhuma escola
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sx={{ maxWidth: "100%" }}>
                      <FormControl
                        sx={{ maxWidth: "100%" }}
                        fullWidth
                        size="small"
                      >
                        <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                          Turma
                        </InputLabel>
                        <Select
                          id="turma"
                          // disabled={buscar !== "" ? true : false}
                          value={turmaSelecao.id}
                          label="Turma"
                          style={{
                            color: theme.cor.cor_preto_300,
                            height: "33px",
                            fontFamily: theme.fonte.font.fonte_1,
                          }}
                          // {...register("tipo")}
                          sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_preto_100,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_principal_500,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: theme.cor.cor_preto_100,
                            },

                            color: theme.cor.cor_preto_300,
                            fontFamily: theme.fonte.font.fonte_1,
                          }}
                        >
                          {turma?.items?.length > 0 ? (
                            turma?.items?.map((c: any) => (
                              <MenuItem
                                onClick={() => setTurmaSelecao(c)}
                                sx={{
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                  "&:hover": {
                                    background: theme.cor.cor_principal_300,
                                    color: theme.cor.cor_branco_100,
                                  },
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                  "&.Mui-selected": {
                                    background: theme.cor.cor_principal_200,
                                    color: theme.cor.cor_branco_100,
                                  },
                                }}
                                key={c.id}
                                value={c.id}
                              >
                                {c.nome}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              sx={{
                                color: theme.cor.cor_preto_300,
                                fontFamily: theme.fonte.font.fonte_1,
                                "&:hover": {
                                  background: theme.cor.cor_principal_300,
                                  color: theme.cor.cor_branco_100,
                                },
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                "&.Mui-selected": {
                                  background: theme.cor.cor_principal_200,
                                  color: theme.cor.cor_branco_100,
                                },
                              }}
                              key={-3}
                              value={-1}
                            >
                              Nenhuma turma
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          background: theme.cor.cor_principal_500,
                          color: theme.cor.cor_branco_100,
                          fontFamily: theme.fonte.font.fonte_1,
                        }}
                        endIcon={<AddIcon style={{ marginTop: "-.1rem" }} />}
                        variant={"contained"}
                        size="small"
                        onClick={() => {
                          setConfiguracao((antes: any) => {
                            return { ...antes, criar: true };
                          });
                        }}
                        disabled={configuracao?.carregar}
                      >
                        CRIAR TURMA
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {configuracao?.carregar ? (
                  <Grid
                    item
                    xs={12}
                    marginTop={"2.5rem"}
                    paddingBottom={"1rem"}
                  >
                    <CarregarLoading />
                  </Grid>
                ) : turmaSelecao?.id !== -1 && pessoas?.items?.length > 0 ? (
                  <Grid
                    item
                    xs={12}
                    marginTop={"2.5rem"}
                    paddingBottom={"1rem"}
                  >
                    <StyledTable>
                      <TableHead
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                          borderRadius: "1rem",
                        }}
                      >
                        <TableRow>
                          <TableCell align="center">
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "100%",
                                      width: "40px",
                                      height: "40px",
                                      background: theme.cor.cor_principal_500,
                                      cursor: "pointer",
                                      "&:hover": {
                                        opacity: 0.5,
                                      },
                                      boxShadow:
                                        "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                                    }}
                                    onClick={() => {
                                      setConfiguracao((antes: any) => {
                                        return { ...antes, editar: true };
                                      });
                                    }}
                                  >
                                    <EditIcon
                                      style={{ fill: theme.cor.cor_branco_100 }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <p
                                    style={{
                                      fontWeight: theme.fonte.bold.bold,
                                      fontSize: "20px",
                                      fontFamily: theme.fonte.font.fonte_1,
                                      color: theme.cor.cor_preto_300,
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    {turmaSelecao?.nome} | {pessoas?.total}{" "}
                                    matriculados
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>

                          <TableCell
                            style={{
                              borderLeft:
                                "1px solid" + theme.cor.cor_branco_500,
                            }}
                            align="center"
                          >
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item>
                                  <p
                                    style={{
                                      fontWeight: theme.fonte.bold.bold,
                                      fontSize: "20px",
                                      fontFamily: theme.fonte.font.fonte_1,
                                      color: theme.cor.cor_preto_300,
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    N° matricula
                                  </p>
                                </Grid>
                                <Grid item>
                                  <DeleteIcon
                                    style={{
                                      height: "20px",
                                      marginTop: ".5rem",
                                      fill: theme.cor.cor_preto_300,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setOpen(true)}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <div style={{ height: "2rem" }}></div>
                      <TableBody
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                          borderRadius: "1rem",
                        }}
                      >
                        {pessoas?.items?.length > 0
                          ? pessoas?.items?.map((p: any, i: number) => (
                              <TableRow
                                key={p.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  background: theme.cor.cor_branco_100,
                                }}
                              >
                                <TableCell
                                  align="left"
                                  style={{
                                    borderBottom:
                                      pessoas?.items?.length - 1 !== i
                                        ? "1px solid" + theme.cor.cor_branco_500
                                        : "",
                                  }}
                                  key={p.id_keycloak}
                                >
                                  <p
                                    style={{
                                      fontWeight: theme.fonte.bold.medio,
                                      fontSize: "14px",
                                      fontFamily: theme.fonte.font.normal,
                                      color: theme.cor.cor_preto_300,
                                      padding: 0,
                                      margin: 0,
                                      paddingLeft: "1rem",
                                    }}
                                  >
                                    {p.nome}
                                  </p>
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderLeft:
                                      "1px solid" + theme.cor.cor_branco_500,
                                    borderBottom:
                                      pessoas?.items?.length - 1 !== i
                                        ? "1px solid" + theme.cor.cor_branco_500
                                        : "",
                                  }}
                                  align="center"
                                >
                                  <p
                                    style={{
                                      fontWeight: theme.fonte.bold.medio,
                                      fontSize: "14px",
                                      fontFamily: theme.fonte.font.normal,
                                      color: theme.cor.cor_preto_300,
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    {p.id_keycloak}
                                  </p>
                                </TableCell>
                              </TableRow>
                            ))
                          : ""}
                      </TableBody>
                    </StyledTable>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    marginTop={"2.5rem"}
                    paddingBottom={"1rem"}
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        <StyledResultAntd
                          status="success"
                          title="Nenhum dado encontrado!"
                          subTitle="Nenhum dado encontrado!"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          {configuracao?.carregar || configuracao?.criar ? (
            ""
          ) : (
            <Paginacao item={pessoas} handlePage={handlePage} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Turmas;
