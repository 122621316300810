import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { Grid, Link } from "@mui/material";
import { Card, List, Space } from "antd";
import Search from "antd/es/transfer/search";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { convertToEmbedUrl, getTipoConta, validarLink } from "../../types";
import api from "../axios/axios";
import Carregar from "../carregar";
import Erro from "../erro";
import { ListContainerStyle, StyledSearchContainer } from "../theme";

function Listaralternativas({ aulaItem, decodedToken = null }: any) {
  const theme = useTheme();
  const [alternativas, setAlternativas] = useState<any>();
  const [aula, setAula] = useState<any>();
  const [alternativasBusca, setAlternativasBusca] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [erro, setErro] = useState(false);
  const [buscar, setBuscar] = useState("");

  useEffect(() => {
    Promise.all([
      api.get(`submissao/respostas/${aulaItem?.id}`),
      api.get(`aula/${aulaItem?.id}`),
    ])
      .then(([r, r2]) => {
        setAlternativas(r?.data);
        setAula(r2?.data);
        setLoading(false);
      })
      .catch((_) => setErro(true));
  }, []);

  useEffect(() => {
    if (loading === false && buscar !== "") {
      const delay = setTimeout(() => {
        if (buscar.trim() !== "") {
          setAlternativasBusca(
            alternativas?.filter((item: any) =>
              item?.id_keycloak?.toLowerCase().includes(buscar.toLowerCase())
            )
          );
        }
      }, 2000);

      return () => clearTimeout(delay);
    }
  }, [buscar]);

  const handleBuscar = (e: any) => {
    setBuscar(e.target.value);
  };

  if (erro) return <Erro />;

  if (loading) return <Carregar />;

  const respostaCerta = (perguntaItem: any, respostaItem: any) => {
    if (perguntaItem.alternativas.length === 0) {
      return "Concluído!";
    }

    const r = respostaItem.respostas.filter(
      (_: any) => _?.id_atividade === perguntaItem?.id
    );

    if (r.length > 0) {
      if (r[0].id_alternativa === null) return "Nenhuma seleção";
      const selecao = perguntaItem.alternativas.filter(
        (_: any) => _.id === r[0].id_alternativa
      );
      if (selecao.length > 0) return selecao[0].nome;
      return "Nenhuma seleção";
    }
    return "Nenhuma seleção";
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        spacing={1}
        style={{ marginBottom: "2rem" }}
      >
        <Grid
          item
          xs={12}
          className="boxSombra"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <p
            style={{
              fontSize: "2rem",
              textAlign: "center",
              fontFamily: theme.fonte.font.fonte_1,
              color: theme.cor.cor_preto_300,
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Aula:{" "}
            </span>
            <span>{aula.nome}</span>
          </p>
          {aula.pontuacao ? (
            <p
              style={{
                fontSize: "1.3rem",
                textAlign: "center",
                marginTop: "-1rem",
                fontFamily: theme.fonte.font.fonte_1,
                color: theme.cor.cor_preto_300,
              }}
            >
              <span style={{ fontWeight: "bold" }}>Pontuação: </span>
              <span>{aula.pontuacao}</span>
            </p>
          ) : (
            ""
          )}

          <Grid item xs={12} style={{ marginRight: ".5rem" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <iframe
                  style={{
                    border: 0,
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: "1rem",
                    fontFamily: theme.fonte.font.fonte_1,
                    color: theme.cor.cor_preto_300,
                    fontWeight: theme.fonte.bold.medio,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    aspectRatio: "16 / 9",
                    width: "100%",
                    height: "auto",
                  }}
                  allow="fullscreen"
                  src={convertToEmbedUrl(aula.link) || aula.link}
                />
              </Grid>
            </Grid>
          </Grid>

          {aula?.anexo?.length > 0 ? (
            <>
              <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    background: theme.cor.cor_branco,
                    padding: "1rem",
                    borderRadius: "1rem",
                    marginTop: "2rem",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    color: theme.cor.cor_preto_300,
                    fontFamily: theme.fonte.fonte,
                  }}
                >
                  <Grid item xs={12} style={{ fontWeight: 600 }}>
                    Anexos:
                  </Grid>
                  {aula?.anexo.map((e: any, i: any) => {
                    return (
                      <Grid item xs={12} key={e?.id} paddingLeft={"1rem"}>
                        {i + 1}:{" "}
                        <Link
                          href={`https://drive.google.com/uc?export=download&id=${e?.id}&confirm=yes`}
                        >
                          {e?.nome}
                        </Link>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
            style={{
              marginBottom: "2rem",
              marginTop: "1rem",
              fontFamily: theme.fonte.font.fonte_1,
              color: theme.cor.cor_preto_300,
            }}
          >
            {aula.atividades.map((atv: any, __: number) => (
              <React.Fragment key={atv?.id}>
                {
                  <>
                    <hr style={{ marginLeft: ".5rem", width: "100%" }} />
                    <Grid item>
                      <span style={{ fontWeight: "bold" }}>
                        Quiz: {__ + 1} -{" "}
                      </span>
                      <span>{atv.nome}</span>
                    </Grid>
                    <Grid item style={{ marginRight: ".5rem" }}>
                      <span style={{ fontWeight: "bold" }}>Peso: </span>
                      {atv.peso ? atv.peso : 0}
                    </Grid>
                    <Grid item xs={12} style={{ marginRight: ".5rem" }}>
                      {validarLink(atv.pergunta) === true ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={4}>
                            <iframe
                              style={{
                                border: 0,
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                borderRadius: "1rem",
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                fontWeight: theme.fonte.bold.medio,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                aspectRatio: "16 / 9",
                                width: "100%",
                                height: "auto",
                              }}
                              allow="fullscreen"
                              src={
                                convertToEmbedUrl(atv.pergunta) || atv.pergunta
                              }
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          xs={true}
                          style={{
                            color: "white",
                            backgroundColor: theme.cor.cor_branco_500,
                            // backgroundImage: `url('${
                            //   imagem_turma_listagem[turma?.cor?.hex]
                            // }')`,
                            backgroundRepeat: "repeat",
                            backgroundSize: "auto 10vh",
                            padding: "1rem",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            border: "2px solid black",
                            borderRadius: "1rem",
                            userSelect: "none",
                            fontSize: "20px",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "center",
                              textJustify: "inter-word",
                            }}
                          >
                            {atv.pergunta}
                          </p>
                        </Grid>
                      )}
                    </Grid>
                    {atv.resposta_certa && atv.resposta_certa !== "" ? (
                      <Grid item xs={12} style={{ marginTop: "-.5rem" }}>
                        <span style={{ fontWeight: "bold" }}>Resposta: </span>
                        <span style={{ textAlign: "initial" }}>
                          {
                            atv.alternativas.filter(
                              (r: any) => r?.id === atv.resposta_certa
                            )[0].resposta
                          }
                        </span>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </>
                }
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledSearchContainer>
            <Space direction="vertical" style={{ color: "black" }}>
              <Search
                value={buscar}
                onChange={handleBuscar}
                placeholder="Pesquisar..."
              />
            </Space>
          </StyledSearchContainer>
        </Grid>
        {alternativas?.length > 0 ? (
          <>
            {/* <Grid item xs={12}>
                    <p style={{ marginBottom: "1rem", marginTop: ".5rem", textAlign: 'center' }}>Resumo:</p>
                </Grid> */}
            <ListContainerStyle>
              <List
                locale={{ emptyText: "Não encontrado." }}
                grid={{
                  gutter: 16,
                  column: getTipoConta(decodedToken) === "aluno" ? 1 : 4,
                }}
                dataSource={
                  buscar.trim() !== "" && alternativasBusca
                    ? alternativasBusca
                    : alternativas
                }
                pagination={{
                  pageSize: 10,
                  align: "center",
                }}
                style={{ width: "100%", marginTop: "1rem" }}
                renderItem={(res: any) => (
                  <List.Item>
                    <React.Fragment key={res?.user}>
                      <Card
                        style={{
                          marginLeft: "1rem",
                          background: theme.cor.cor_branco_100,
                          borderColor: theme.cor.cor_branco_300,
                          width: "100%",
                        }}
                      >
                        <Grid item xs={12}>
                          <Grid container justifyContent={"end"}>
                            <Grid item overflow={"auto"}>
                              {res.timestamp ? (
                                <span
                                  style={{
                                    fontFamily: theme.fonte.font.fonte_1,
                                    color: theme.cor.cor_preto_300,
                                  }}
                                >
                                  {
                                    new Date(new Date(res?.timestamp).getTime())
                                      .toLocaleString()
                                      .split(",")[0]
                                  }
                                  <br /> 
                                </span>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {/* <Grid item>
                            <span
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0rem",
                              }}
                            >
                              {res?.id_keycloak}
                            </span>
                            <br />
                          </Grid> */}
                            <Grid item xs={12}></Grid>
                            <Grid item>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "0rem",
                                  fontFamily: theme.fonte.font.fonte_1,
                                  color: theme.cor.cor_preto_300,
                                }}
                              >
                                {res?.id_keycloak}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            justifyContent={"center"}
                            style={{
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_300,
                            }}
                          >
                            <Grid item xs={12}>
                              <br />
                            </Grid>
                            Nota: {res?.nota}%
                            {/* <Grid item>
                            {aula.atividades?.length > 0 && aula?.avaliacao !== 2
                              ? "Nota: " +
                                calcular_pontuacao(
                                  aula.atividades.map((_: Atividade) => _.nota),
                                  aula.atividades.map(
                                    (perguntaItem: Atividade, __: number) => {
                                      const r = res.alternativas.filter(
                                        (_: Resposta) =>
                                          perguntaItem?.id === _?.id_atividade
                                      );

                                      if (
                                        perguntaItem.respostas?.length === 0 ||
                                        r[0]?.id ===
                                          perguntaItem.resposta_certa
                                      )
                                        return perguntaItem.nota;
                                      else return 0;
                                    }
                                  ),
                                  aula.pontuacao
                                ) +
                                "%"
                              : "Nota: 100%"}
                          </Grid> */}
                          </Grid>
                        </Grid>
                        {/* <br /> */}
                        {aula.atividades?.length > 0 ? (
                          <>
                            <Grid item xs={12}>
                              <Grid container justifyContent={"center"}>
                                <Grid item>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "0rem",
                                      fontFamily: theme.fonte.font.fonte_1,
                                      color: theme.cor.cor_preto_300,
                                    }}
                                  >
                                    Resumo:
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <hr style={{ width: "100%" }} />
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              style={{ marginTop: "-.5rem" }}
                              spacing={1}
                            >
                              {aula.atividades.map(
                                (perguntaItem: any, __: number) => (
                                  <React.Fragment key={perguntaItem?.id}>
                                    <Grid item xs={12}>
                                      <Grid container>
                                        <Grid
                                          item
                                          textAlign={"justify"}
                                          overflow={"auto"}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              color: theme.cor.cor_preto_300,
                                            }}
                                          >
                                            {perguntaItem.nome}:
                                          </span>
                                           {respostaCerta(perguntaItem, res)}
                                          {perguntaItem?.tipo ===
                                          "avaliacao" ? (
                                            <>
                                              {perguntaItem.alternativa_certa ===
                                              res.respostas[__]
                                                ?.id_alternativa ? (
                                                <span
                                                  style={{
                                                    marginTop: "-.15rem",
                                                    position: "absolute",
                                                    fontFamily:
                                                      theme.fonte.font.fonte_1,
                                                    color:
                                                      theme.cor.cor_preto_300,
                                                  }}
                                                >
                                                  <CheckBoxIcon
                                                    style={{ color: "green" }}
                                                  />
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    marginTop: "-.15rem",
                                                    position: "absolute",
                                                    fontFamily:
                                                      theme.fonte.font.fonte_1,
                                                    color:
                                                      theme.cor.cor_preto_300,
                                                  }}
                                                >
                                                  {perguntaItem?.tipo ===
                                                  "quiz" ? (
                                                    <CheckBoxIcon
                                                      style={{ color: "green" }}
                                                    />
                                                  ) : (
                                                    <DangerousIcon
                                                      style={{ color: "red" }}
                                                    />
                                                  )}
                                                </span>
                                              )}
                                            </>
                                          ) : (
                                            <span
                                              style={{
                                                marginTop: "-.15rem",
                                                position: "absolute",
                                                fontFamily:
                                                  theme.fonte.font.fonte_1,
                                                color: theme.cor.cor_preto_300,
                                              }}
                                            >
                                              <CheckBoxIcon
                                                style={{ color: "green" }}
                                              />
                                            </span>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <hr style={{ width: "100%" }} />
                                  </React.Fragment>
                                )
                              )}
                            </Grid>
                          </>
                        ) : (
                          ""
                        )}
                      </Card>
                    </React.Fragment>
                  </List.Item>
                )}
              />
            </ListContainerStyle>
          </>
        ) : (
          <Grid item xs={12}>
            <p
              style={{
                marginBottom: "0rem",
                marginTop: ".5rem",
                textAlign: "center",
                fontFamily: theme.fonte.font.fonte_1,
                color: theme.cor.cor_preto_300,
              }}
            >
              Nenhuma resposta.
            </p>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Listaralternativas;
