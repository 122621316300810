import axios from "axios";
import { keycloak } from "../..";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BACK,
});

api.interceptors.request.use(async (config) => {
  let token = await keycloak.token;

  if (
    config.url?.indexOf("/cadastro/govtech") === -1 &&
    config.url?.indexOf("/recuperar") === -1
  ) {
    await keycloak.updateToken(5).then(async function (refreshed) {
      if (refreshed) token = await keycloak.token;
    });
  }
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Content-Type"] = "application/json";
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Accept"] = "application/json";

  return config;
});

export default api;
