import {useEffect} from "react";
import {addResponseMessage, markAllAsRead, Widget} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import SiGooglegemini from "../../img/google-gemini-icon.png";
import {ComponentGemini} from "../theme";
import chat_gemini from "./gemini.service";

function Chat() {
  const handleNewUserMessage = (newMessage: any) => {
    const msg = document.getElementsByClassName("loader");

    if (msg.length > 0) {
      msg[0].className = "rcw-message chat_loading_text";
    }

    chat_gemini(newMessage)
      .then((responseText) => {
        addResponseMessage(responseText);
      })
      .catch((error) => {
        addResponseMessage(
          "Desculpe, estamos tendo problemas para conectar ao servidor. Por favor, tente novamente mais tarde."
        );
        console.log(error);
      })
      .finally(() => {
        const msg = document.getElementsByClassName("chat_loading_text");
        if (msg.length > 0) {
          msg[0].className = "loader";
        }
      });
  };

  useEffect(() => {
    markAllAsRead();
  }, []);

  return (
    <ComponentGemini>
      <Widget
        title={"Bem-vindo ao Gemini AI"}
        senderPlaceHolder={"Digite uma mensagem"}
        emojis={false}
        profileAvatar={SiGooglegemini}
        subtitle={
          <p style={{ marginTop: "-1rem", marginBottom: "-.5rem" }}>
            Sou Gemini, um chatbot
            <br />
            que pode te ajudar com suas dúvidas.
          </p>
        }
        handleNewUserMessage={handleNewUserMessage}
      />
    </ComponentGemini>
  );
}

export default Chat;
