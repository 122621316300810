import AvatarProfessor from "./img/130 PX.png";
import AvatarGestor from "./img/Ellipse 15 (1).png";
import AvatarAluno from "./img/Ellipse 15.png";
import Govtech from "./img/logo/goias.png";
import Sappens from "./img/logo/sappens.png";
import Ips from "./img/logo/ips.png";
import Cesan from "./img/logo/cesan.png";

export const validarLink = (str: any) => {
  const pattern =
    /^(?:(?:https?:\/\/)|(?:www\.)|localhost|genial\.(?:ly|me)|vimeo\.com|youtube\.com|youtu\.be)(?:[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/g;
  return pattern.test(str);
};

export const setTitle = (contrato: any) => {
  const decodedToken = { contrato: contrato };

  document.title = getNomeContrato(decodedToken) as string;
  // const ico = document.getElementById("favicon") as HTMLLinkElement;
  // ico.href = getLogoContrato(decodedToken);
};

export const hexToRgb = (hex: any) => {
  hex = hex.replace(/^#/, "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r}, ${g}, ${b}`;
};

export const convertToEmbedUrl = (url: any) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

  if (youtubeRegex.test(url)) {
    let videoId = "";

    if (url.includes("youtu.be")) {
      videoId = url.substring(url.lastIndexOf("/") + 1);
    } else {
      videoId = url.match(/[?&]v=([^&]+)/)[1];
    }

    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    return null; // URL não é válida para o YouTube
  }
};

export interface AuthToken {
  given_name: string;
  foto: string;
  contrato: string;
  preferred_username: string;
  realm_access: {
    roles: string[];
  };
  contratos: string[];
  tema: {};
}

export const getAvatarFoto = (decodedToken: any): string => {
  const professor: string = decodedToken?.realm_access?.roles?.includes(
    "professor"
  )
    ? "professor"
    : decodedToken?.realm_access?.roles?.includes("aluno")
    ? "aluno"
    : decodedToken?.realm_access?.roles?.includes("gestor")
    ? "gestor"
    : "";

  return professor === "professor"
    ? AvatarAluno
    : professor === "aluno"
    ? AvatarProfessor
    : professor === "gestor"
    ? AvatarGestor
    : "";
};

export const getTipoConta = (decodedToken: any): string => {
  return decodedToken?.realm_access?.roles?.includes("admin")
    ? "admin"
    : decodedToken?.realm_access?.roles?.includes("professor")
    ? "professor"
    : decodedToken?.realm_access?.roles?.includes("aluno")
    ? "aluno"
    : decodedToken?.realm_access?.roles?.includes("gestor")
    ? "gestor"
    : "";
};

export const getAcessoNivel2 = (decodedToken: any): boolean => {
  return decodedToken?.realm_access?.roles?.includes("professor") ||
    decodedToken?.realm_access?.roles?.includes("admin") ||
    decodedToken?.realm_access?.roles?.includes("gestor")
    ? true
    : false;
};

export const getAcessoNivel3 = (decodedToken: any): boolean => {
  return decodedToken?.realm_access?.roles?.includes("admin") ||
    decodedToken?.realm_access?.roles?.includes("gestor")
    ? true
    : false;
};

export const getAcessoNivel1 = (decodedToken: any): boolean => {
  return decodedToken?.realm_access?.roles?.includes("aluno") ||
    decodedToken?.realm_access?.roles?.includes("professor") ||
    decodedToken?.realm_access?.roles?.includes("admin") ||
    decodedToken?.realm_access?.roles?.includes("gestor")
    ? true
    : false;
};

export const getLogoContrato = (decodedToken: any) => {
  if (decodedToken.contrato === "govtech") {
    return Govtech;
  } else if (decodedToken.contrato === "sappens") {
    return Sappens;
  } else if (decodedToken.contrato === "cesan") {
    return Cesan;
  } else if (decodedToken.contrato === "ips") {
    return Ips;
  } else {
    return "none";
  }
};

export const getGestor = (decodedToken: any) => {
  return decodedToken?.realm_access?.roles?.includes("gestor");
};

export const getNomeContrato = (decodedToken: any) => {
  if (decodedToken?.contrato === "govtech" || decodedToken === "govtech") {
    return "GovTech";
  } else if (
    decodedToken?.contrato === "sappens" ||
    decodedToken === "sappens"
  ) {
    return "Sappens";
  } else if (decodedToken?.contrato === "cesan" || decodedToken === "cesan") {
    return "Cesan";
  } else if (decodedToken?.contrato === "ips" || decodedToken === "ips") {
    return "IPS";
  } else {
    return false;
  }
};

export const getComprarContrato = (
  decodedToken: any,
  contrato: any
): boolean => {
  return decodedToken.contrato === contrato;
};

export const getContratoIps = (decodedToken: any) => {
  if (decodedToken.contrato === "ips") return true;
  else return false;
};
