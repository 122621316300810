import CancelIcon from "@mui/icons-material/Cancel";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import PersonIcon from "@mui/icons-material/Person";
import SaveIcon from "@mui/icons-material/Save";
import {LoadingButton} from "@mui/lab";
import {Avatar, Box, Button, Grid} from "@mui/material";
import Modal from "@mui/material/Modal";
import clsx from "clsx";
import * as React from "react";
import {useState} from "react";
import {toast} from "react-toastify";
import {useTheme} from "styled-components";
import foto_aluno1 from "../../img/perfil/aluno/avatar-(1).jpg";
import foto_aluno2 from "../../img/perfil/aluno/avatar-(10).jpg";
import foto_aluno3 from "../../img/perfil/aluno/avatar-(11).jpg";
import foto_aluno4 from "../../img/perfil/aluno/avatar-(12).jpg";
import foto_aluno5 from "../../img/perfil/aluno/avatar-(13).jpg";
import foto_aluno6 from "../../img/perfil/aluno/avatar-(14).jpg";
import foto_aluno7 from "../../img/perfil/aluno/avatar-(15).jpg";
import foto_aluno8 from "../../img/perfil/aluno/avatar-(16).jpg";
import foto_aluno9 from "../../img/perfil/aluno/avatar-(17).jpg";
import foto_aluno10 from "../../img/perfil/aluno/avatar-(18).jpg";
import foto_aluno11 from "../../img/perfil/aluno/avatar-(19).jpg";
import foto_aluno12 from "../../img/perfil/aluno/avatar-(2).jpg";
import foto_aluno13 from "../../img/perfil/aluno/avatar-(20).jpg";
import foto_aluno14 from "../../img/perfil/aluno/avatar-(21).jpg";
import foto_aluno15 from "../../img/perfil/aluno/avatar-(22).jpg";
import foto_aluno16 from "../../img/perfil/aluno/avatar-(23).jpg";
import foto_aluno17 from "../../img/perfil/aluno/avatar-(24).jpg";
import foto_aluno18 from "../../img/perfil/aluno/avatar-(25).jpg";
import foto_aluno19 from "../../img/perfil/aluno/avatar-(26).jpg";
import foto_aluno20 from "../../img/perfil/aluno/avatar-(27).jpg";
import foto_aluno21 from "../../img/perfil/aluno/avatar-(28).jpg";
import foto_aluno22 from "../../img/perfil/aluno/avatar-(29).jpg";
import foto_aluno23 from "../../img/perfil/aluno/avatar-(3).jpg";
import foto_aluno24 from "../../img/perfil/aluno/avatar-(30).jpg";
import foto_aluno25 from "../../img/perfil/aluno/avatar-(4).jpg";
import foto_aluno26 from "../../img/perfil/aluno/avatar-(5).jpg";
import foto_aluno27 from "../../img/perfil/aluno/avatar-(6).jpg";
import foto_aluno28 from "../../img/perfil/aluno/avatar-(7).jpg";
import foto_aluno29 from "../../img/perfil/aluno/avatar-(8).jpg";
import foto_aluno30 from "../../img/perfil/aluno/avatar-(9).jpg";
import foto_professor1 from "../../img/perfil/professor/P01.jpg";
import foto_professor2 from "../../img/perfil/professor/P02.jpg";
import foto_professor3 from "../../img/perfil/professor/P03.jpg";
import foto_professor4 from "../../img/perfil/professor/P04.jpg";
import foto_professor5 from "../../img/perfil/professor/P05.jpg";
import foto_professor6 from "../../img/perfil/professor/P06.jpg";
import foto_professor7 from "../../img/perfil/professor/P07.jpg";
import foto_professor8 from "../../img/perfil/professor/P08.jpg";
import foto_professor9 from "../../img/perfil/professor/P09.jpg";
import foto_professor10 from "../../img/perfil/professor/P10.jpg";
import foto_professor11 from "../../img/perfil/professor/P11.jpg";
import api from "../axios/axios";
import CameraComponent from "./webcam";

export default function EscolherFoto({
  setDecodedToken,
  decodedToken,
  setOpen,
  open,
}: any) {
  const theme = useTheme();
  const [imagem, setImagem] = useState<any>(null);
  const [openImagem, setOpenImagem] = React.useState(false);
  const [avatares, setAvatares] = React.useState<any>([]);
  const [esperar, setEsperar] = useState(false);
  const [fotoPerfil, setfotoPerfil] = useState<any>({
    foto: decodedToken?.foto,
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = React.useRef<any>(null);

  const readFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        const content = event.target.result as string;
        setfotoPerfil({ ...fotoPerfil, foto: content, nome: file?.name });
      }
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    readFile(file);
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const fotos = decodedToken?.realm_access?.roles.includes("aluno")
    ? [
        foto_aluno1,
        foto_aluno2,
        foto_aluno3,
        foto_aluno4,
        foto_aluno5,
        foto_aluno6,
        foto_aluno7,
        foto_aluno8,
        foto_aluno9,
        foto_aluno10,
        foto_aluno11,
        foto_aluno12,
        foto_aluno13,
        foto_aluno14,
        foto_aluno15,
        foto_aluno16,
        foto_aluno17,
        foto_aluno18,
        foto_aluno19,
        foto_aluno20,
        foto_aluno21,
        foto_aluno22,
        foto_aluno23,
        foto_aluno24,
        foto_aluno25,
        foto_aluno26,
        foto_aluno27,
        foto_aluno28,
        foto_aluno29,
        foto_aluno30,
      ]
    : [
        foto_professor1,
        foto_professor2,
        foto_professor3,
        foto_professor4,
        foto_professor5,
        foto_professor6,
        foto_professor7,
        foto_professor8,
        foto_professor9,
        foto_professor10,
        foto_professor11,
      ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    let avatares = [...fotos].sort(() => Math.random() - 0.5);
    setAvatares(avatares.slice(0, 7));
  }, []);

  const enviarFoto = () => {
    toast.info("Enviando foto");
    setEsperar(true);
    api
      .post("perfil/foto", fotoPerfil)
      .then((e) => {
        setDecodedToken({ ...decodedToken, foto: e?.data?.foto });
        setfotoPerfil({ ...fotoPerfil, foto: e?.data?.foto });
        setOpen(false);
        toast.success("Foto envida com sucesso");
      })
      .catch((e) => {
        toast.error("Foto não enviada");
        console.log(e);
      })
      .finally(() => setEsperar(false));
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Grid item>
            <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "auto",
                  bgcolor: theme.cor.cor_branco_100,
                  borderRadius: "10px",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Avatar
                      key={fotoPerfil?.foto}
                      src={fotoPerfil?.foto}
                      style={{
                        width: "6rem",
                        height: "6rem",
                        cursor: "default",
                      }}
                    />
                  </Grid>
                </Grid>
                <h2
                  id="parent-modal-title"
                  className="modal-title"
                  style={{
                    color: theme.cor.cor_preto_300,
                    fontFamily: theme.fonte.font.fonte_1,
                  }}
                >
                  Selecione um avatar
                </h2>

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  style={{ marginTop: "-.5rem", marginBottom: ".5rem" }}
                >
                  {fotos?.map((e: any, i: any) => {
                    return (
                      <Avatar
                        key={i}
                        style={{
                          marginRight: ".5rem",
                          marginTop: ".5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setImagem(e);
                          setOpenImagem(true);
                        }}
                        alt={`Avatar ${i}`}
                        src={e}
                      />
                    );
                  })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: "1rem",
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                  }}
                >
                  <CameraComponent
                    esperar={esperar}
                    setfotoPerfil={setfotoPerfil}
                  />
                </Grid>
                <Grid style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    multiple={false}
                    accept="image/*"
                  />
                  <LoadingButton
                    loading={esperar}
                    loadingPosition="start"
                    startIcon={<InsertPhotoIcon />}
                    disabled={esperar}
                    onClick={handleButtonClick}
                    size="small"
                    variant="contained"
                    style={{
                      width: "100%",

                      background: theme.cor.cor_principal_500,
                      color: theme.cor.cor_branco_100,
                      fontFamily: theme.fonte.font.fonte_1,
                    }}
                  >
                    Upload de foto
                  </LoadingButton>
                </Grid>
                <LoadingButton
                  loading={esperar}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  disabled={esperar || decodedToken?.foto === fotoPerfil.foto}
                  onClick={(e) => enviarFoto()}
                  size="small"
                  variant="contained"
                  style={{
                    width: "100%",
                    background: theme.cor.cor_principal_500,
                    color: theme.cor.cor_branco_100,
                    fontFamily: theme.fonte.font.fonte_1,
                  }}
                >
                  Salvar
                </LoadingButton>
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
                >
                  <ChildModal
                    imagem={imagem}
                    setOpenImagem={setOpenImagem}
                    openImagem={openImagem}
                    setOpen={setOpen}
                    esperar={esperar}
                    decodedToken={decodedToken}
                    setfotoPerfil={setfotoPerfil}
                    setDecodedToken={setDecodedToken}
                  />
                </Grid>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function ChildModal({
  imagem,
  setOpenImagem,
  openImagem,
  setOpen,
  esperar,
  setDecodedToken,
  decodedToken,
  setfotoPerfil,
}: any) {
  const theme = useTheme();
  const handleOpen = () => {
    setOpenImagem(true);
  };
  const handleClose = () => {
    setOpenImagem(false);
  };

  return (
    <React.Fragment>
      <LoadingButton
        loading={esperar}
        loadingPosition="start"
        startIcon={<CancelIcon />}
        disabled={esperar}
        onClick={() => {
          setOpen(false);
          setfotoPerfil({ foto: decodedToken?.foto });
        }}
        style={{
          width: "100%",
          background: theme.cor.cor_erro_500,
          color: theme.cor.cor_branco_100,
          fontFamily: theme.fonte.font.fonte_1,
        }}
        size="small"
        variant="contained"
      >
        Cancelar
      </LoadingButton>

      <Modal open={openImagem} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: theme.cor.cor_branco_100,
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
          style={{ background: theme.cor.cor_branco_100 }}
        >
          <Grid item>
            <h2
              id="child-modal-title"
              className="modal-title"
              style={{
                color: theme.cor.cor_preto_300,
                fontFamily: theme.fonte.font.fonte_1,
              }}
            >
              Avatar
            </h2>
            <Grid item>
              <img src={imagem} style={{ width: "200px" }} alt="avatar" />
            </Grid>
            <Grid item>
              <Button
                startIcon={<PersonIcon />}
                variant="contained"
                style={{
                  width: "100%",
                  background: theme.cor.cor_principal_500,
                  color: theme.cor.cor_branco_100,
                  fontFamily: theme.fonte.font.fonte_1,
                }}
                onClick={() => {
                  handleClose();
                  setfotoPerfil({
                    foto:
                      window.location.href.replace("/configuracoes", "") +
                      imagem,
                    id: -1,
                    nome: imagem.split("/")[imagem.split("/").length - 1],
                  });
                  setOpenImagem(false);
                }}
              >
                Confirmar
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<CancelIcon />}
                color="error"
                variant="contained"
                style={{
                  width: "100%",
                  background: theme.cor.cor_erro_500,
                  color: theme.cor.cor_branco_100,
                  marginTop: ".5rem",

                  fontFamily: theme.fonte.font.fonte_1,
                }}
                onClick={() => {
                  setOpenImagem(false);
                }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});
