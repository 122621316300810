import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Keycloak from "keycloak-js";
import {ConfigProvider} from "antd";
import ptBR from "antd/es/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL ?? "",
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? "",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID ?? "",
});

keycloak
  .init({ onLoad: "login-required" })
  .then((authenticated) => {
    if (authenticated) {
      const rootElement = document.getElementById("root");
      ReactDOM.render(
        <React.StrictMode>
          <ConfigProvider locale={ptBR}>
            <ToastContainer />
            <App />
          </ConfigProvider>
        </React.StrictMode>,
        rootElement
      );
    } else {
      console.error("Usuário não autenticado");
    }
  })
  .catch((error) => {
    console.error("Erro na inicialização do Keycloak:", error);
  });

export { keycloak };
