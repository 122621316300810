import {Dialog, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select,} from "@mui/material";
import {useEffect, useState} from "react";

import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {LoadingButton} from "@mui/lab";
import {useTheme} from "styled-components";
import api from "../axios/axios";

const Filtro = ({
  setOpenItem,
  openItem,
  configuracaoGlobal,
  setConfiguracaoGlobal,
  setFila,
  fila,
  limparFiltro,
  setLimparFiltro,
}: any) => {
  const theme = useTheme();
  const [configuracao, setConfiguracao] = useState<any>({
    filtro: false,
    cidadeSelecao: {
      id: -1,
      nome: "Nenhuma cidade",
    },
    escolaSelecao: {
      id: -1,
      nome: "Nenhuma escola",
    },
    pessoaSelecao: {
      id_keycloak: -1,
      nome: "Nenhuma pessoa",
    },
    recadosProfessores: {
      items: [],
      total: 0,
    },
    recadosEstudantes: {
      items: [],
      total: 0,
    },
    selecao: "professor",
    avancado: false,
    carregarMais: false,
    enviar: false,
    loading: true,
    erro: false,
    loadingRecado: true,
    loadingRecadoFiltro: false,
  });

  useEffect(() => {
    api.get("cidade").then((r) => {
      r.data.items.unshift({ id: -1, nome: "Nenhuma cidade" });
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          cidade: r.data,
        };
      });
    });
  }, []);

  useEffect(() => {
    if (limparFiltro) {
      setConfiguracao({
        filtro: false,
        cidadeSelecao: {
          id: -1,
          nome: "Nenhuma cidade",
        },
        escolaSelecao: {
          id: -1,
          nome: "Nenhuma escola",
        },
        pessoaSelecao: {
          id_keycloak: -1,
          nome: "Nenhuma pessoa",
        },
        recadosProfessores: {
          items: [],
          total: 0,
        },
        recadosEstudantes: {
          items: [],
          total: 0,
        },
        selecao: "professor",
        avancado: false,
        carregarMais: false,
        enviar: false,
        loading: true,
        erro: false,
        loadingRecado: true,
        loadingRecadoFiltro: false,
      });
      api.get("cidade").then((r) => {
        r.data.items.unshift({ id: -1, nome: "Nenhuma cidade" });
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            cidade: r.data,
          };
        });

        setConfiguracaoGlobal((antes: any) => {
          return {
            ...antes,
            cidade: r.data,
          };
        });
      });
      setLimparFiltro(false);
    }
  }, [limparFiltro]);

  useEffect(() => {
    if (configuracao?.cidadeSelecao?.id !== -1) {
      api.get("cidade/escola/" + configuracao?.cidadeSelecao?.id).then((r) => {
        r.data.items.unshift({ id: -1, nome: "Nenhuma escola" });
        setConfiguracao((antes: any) => {
          return { ...antes, escola: r.data };
        });
        setConfiguracaoGlobal((antes: any) => {
          return { ...antes, escola: r.data };
        });
      });
    }
  }, [configuracao?.cidadeSelecao]);

  useEffect(() => {
    if (configuracao?.escolaSelecao?.id !== -1) {
      api
        .get(
          `cidade/escola/pessoa/${
            configuracaoGlobal?.selecao === "professor" ? "professor" : "aluno"
          }/${configuracao?.escolaSelecao?.id}`
        )
        .then((r) => {
          r.data.items.unshift({ id_keycloak: -1, nome: "Nenhuma pessoa" });
          setConfiguracao((antes: any) => {
            return { ...antes, pessoa: r.data };
          });
          setConfiguracaoGlobal((antes: any) => {
            return { ...antes, pessoa: r.data };
          });
        });
    }
  }, [configuracao?.escolaSelecao]);

  const handleCloseItem = () => setOpenItem(false);

  const buscar = () => {
    const url = `recado/filtro?${
      configuracao?.cidadeSelecao?.id !== -1
        ? "id_cidade=" + configuracao?.cidadeSelecao?.id
        : ""
    }${
      configuracao?.escolaSelecao?.id !== -1
        ? "&id_escola=" + configuracao?.escolaSelecao?.id
        : ""
    }${
      configuracao?.pessoaSelecao?.id_keycloak !== -1
        ? "&id_pessoa=" + configuracao?.pessoaSelecao?.id_keycloak
        : ""
    } ${
      configuracaoGlobal?.selecao === "professor"
        ? "&tipo=professor"
        : "&tipo=aluno"
    }`;

    setConfiguracaoGlobal((antes: any) => {
      return {
        ...antes,
        enviar: true,
        loadingRecadoFiltro: true,
        buscarFiltro: true,
      };
    });

    api
      .get(url)
      .then((r) => {
        if (configuracaoGlobal?.selecao === "professor") {
          setConfiguracaoGlobal((antes: any) => {
            return {
              ...antes,
              recadosProfessores: r.data,
              filtro: true,
            };
          });
        } else {
          setConfiguracaoGlobal((antes: any) => {
            return {
              ...antes,
              recadosEstudantes: r.data,
              filtro: true,
            };
          });
        }
        const fila = [];

        if (r?.data?.items?.length > 0) {
          fila.push(r.data.items[0]);
        } else {
          fila.push(null);
        }
        setFila(fila);
      })
      .then(() => {
        setOpenItem(false);
      })
      .finally(() => {
        setConfiguracaoGlobal((antes: any) => {
          return {
            ...antes,
            loadingRecadoFiltro: false,
            enviar: false,
          };
        });
      });
  };

  const handlePage = () => {
    setConfiguracaoGlobal((antes: any) => {
      return { ...antes, carregarMais: true };
    });
    const page =
      configuracaoGlobal?.selecao !== "professor"
        ? configuracaoGlobal?.recadosEstudantes?.page + 1
        : configuracao?.recadosProfessores?.page + 1;
    const url = `recado/filtro?${
      configuracao?.cidadeSelecao?.id !== -1
        ? "id_cidade=" + configuracao?.cidadeSelecao?.id
        : ""
    }${
      configuracao?.escolaSelecao?.id !== -1
        ? "&id_escola=" + configuracao?.escolaSelecao?.id
        : ""
    }${
      configuracao?.pessoaSelecao?.id_keycloak !== -1
        ? "&id_pessoa=" + configuracao?.pessoaSelecao?.id_keycloak
        : ""
    } ${
      configuracaoGlobal?.selecao === "professor"
        ? "&tipo=professor"
        : "&tipo=aluno"
    }`;

    api
      .get(`${url}&page=${page}`)
      .then((r1) => {
        setConfiguracaoGlobal((antes: any) => {
          if (configuracaoGlobal?.selecao === "professor") {
            const updatedRecadosProfessores = {
              items: [
                ...(antes?.recadosProfessores?.items || []),
                ...r1?.data?.items,
              ],
              total: r1?.data?.total,
              page: r1?.data?.page,
            };

            return {
              ...antes,
              recadosProfessores: updatedRecadosProfessores,
            };
          } else if (configuracaoGlobal?.selecao === "aluno") {
            const updatedRecadosEstudantes = {
              items: [
                ...(antes?.recadosEstudantes?.items || []),
                ...r1?.data?.items,
              ],
              total: r1?.data?.total,
              page: r1?.data?.page,
            };

            return {
              ...antes,
              recadosEstudantes: updatedRecadosEstudantes,
            };
          }

          return antes;
        });
      })
      .finally(() => {
        setConfiguracaoGlobal((antes: any) => {
          return { ...antes, carregarMais: false };
        });
      });
  };

  useEffect(() => {
    if (configuracaoGlobal?.page) {
      handlePage();
      setConfiguracaoGlobal((antes: any) => {
        return { ...antes, page: false };
      });
    }
  }, [configuracaoGlobal?.page]);

  const limpar = () => {
    setConfiguracao((antes: any) => {
      return {
        ...antes,
        loadingRecado: true,
        loadingRecadoFiltro: true,
        filtro: false,
        buscarFiltro: false,
      };
    });
    if (configuracaoGlobal?.selecao === "professor") {
      api
        .get(`/recado/tipo/professor`)
        .then((r1) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              recadosProfessores: r1?.data,
              cidadeSelecao: {
                id: -1,
                nome: "Nenhuma cidade",
              },
              escolaSelecao: {
                id: -1,
                nome: "Nenhuma escola",
              },
              pessoaSelecao: {
                id_keycloak: -1,
                nome: "Nenhuma pessoa",
              },
            };
          });

          setConfiguracaoGlobal((antes: any) => {
            return {
              ...antes,
              recadosProfessores: r1?.data,
              cidadeSelecao: {
                id: -1,
                nome: "Nenhuma cidade",
              },
              escolaSelecao: {
                id: -1,
                nome: "Nenhuma escola",
              },
              pessoaSelecao: {
                id_keycloak: -1,
                nome: "Nenhuma pessoa",
              },
            };
          });

          const fila = [];

          if (r1?.data?.items?.length > 0) {
            fila.push(r1.data.items[0]);
          } else {
            fila.push(null);
          }
          setOpenItem(false);
          setFila(fila);
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
              loadingRecado: false,
              loadingRecadoFiltro: false,
            };
          })
        );

      if (configuracaoGlobal?.avancado) {
        api.get("cidade").then((r) => {
          r.data.items.unshift({ id: -1, nome: "Nenhuma cidade" });
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              cidade: r.data,
              escola: {
                items: [],
              },
              pessoa: {
                items: [],
              },
            };
          });
        });
      }
    } else {
      api
        .get(`/recado/tipo/aluno`)
        .then((r2) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              recadosEstudantes: r2?.data,
              cidadeSelecao: {
                id: -1,
                nome: "Nenhuma cidade",
              },
              escolaSelecao: {
                id: -1,
                nome: "Nenhuma escola",
              },
              pessoaSelecao: {
                id_keycloak: -1,
                nome: "Nenhuma pessoa",
              },
            };
          });
          setConfiguracaoGlobal((antes: any) => {
            return {
              ...antes,
              recadosEstudantes: r2?.data,
              cidadeSelecao: {
                id: -1,
                nome: "Nenhuma cidade",
              },
              escolaSelecao: {
                id: -1,
                nome: "Nenhuma escola",
              },
              pessoaSelecao: {
                id_keycloak: -1,
                nome: "Nenhuma pessoa",
              },
            };
          });
          const fila = [];

          if (r2?.data?.items?.length > 0) {
            fila.push(r2.data.items[0]);
          } else {
            fila.push(null);
          }
          setOpenItem(false);
          setFila(fila);
          if (configuracao?.avancado) {
            api.get("cidade").then((r) => {
              r.data.items.unshift({ id: -1, nome: "Nenhuma cidade" });
              setConfiguracao((antes: any) => {
                return {
                  ...antes,
                  cidade: r.data,
                  escola: {
                    items: [],
                  },
                  pessoa: {
                    items: [],
                  },
                };
              });
            });
          }
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loadingRecado: false,
              loadingRecadoFiltro: false,
            };
          })
        );
    }
  };
  return (
    <Dialog
      onClose={handleCloseItem}
      open={openItem}
      PaperProps={{
        sx: {
          borderRadius: "1.5rem",
          background: theme.cor.cor_branco_100,
        },
      }}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
        <IconButton
          aria-label="close"
          onClick={handleCloseItem}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.cor.cor_preto_400,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          color: theme.cor.cor_preto_300,
          fontFamily: theme.fonte.font.fonte_1,
          fontSize: "18px",
          marginBottom: "1rem",
          marginTop: "-1rem",
          paddingTop: "0rem",
          borderTopLeftRadius: ".5rem",
          borderTopRightRadius: ".5rem",
          padding: "2rem",
        }}
      >
        <Grid item xs={12} paddingLeft={"3rem"} paddingTop={"1rem"}>
          <Grid item xs={11}>
            <FormControl fullWidth size="small">
              <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                Cidade
              </InputLabel>
              <Select
                value={configuracao?.cidadeSelecao?.id}
                disabled={configuracao?.cidade?.items?.length === 0}
                label="Cidade"
                name="cidade"
                style={{ color: theme.cor.cor_preto_500 }}
              >
                {configuracao?.cidade?.items?.length > 0 ? (
                  configuracao?.cidade?.items?.map((e: any) => (
                    <MenuItem
                      onClick={(_) => {
                        setConfiguracao((antes: any) => {
                          return {
                            ...antes,
                            cidadeSelecao: e,
                            escolaSelecao: {
                              id: -1,
                              nome: "Nenhuma escola",
                            },
                            pessoaSelecao: {
                              id_keycloak: -1,
                              nome: "Nenhuma pessoa",
                            },
                            escola: {
                              items: [],
                            },
                            pessoa: {
                              items: [],
                            },
                          };
                        });

                        setConfiguracaoGlobal((antes: any) => {
                          return {
                            ...antes,
                            cidadeSelecao: e,
                            escolaSelecao: {
                              id: -1,
                              nome: "Nenhuma escola",
                            },
                            pessoaSelecao: {
                              id_keycloak: -1,
                              nome: "Nenhuma pessoa",
                            },
                            escola: {
                              items: [],
                            },
                            pessoa: {
                              items: [],
                            },
                          };
                        });
                      }}
                      key={e.id}
                      value={e.id}
                    >
                      {e.nome}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={-1} disabled>
                    Nenhuma cidade
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={11} style={{ marginTop: "1rem" }}>
            <FormControl fullWidth size="small">
              <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                Escola
              </InputLabel>
              <Select
                id="escola-filtro"
                value={configuracao?.escolaSelecao?.id}
                label="Escola"
                name="escola"
                style={{ color: theme.cor.cor_preto_500 }}
                disabled={configuracao?.cidadeSelecao?.id === -1 ? true : false}
              >
                {configuracao?.escola?.items?.length > 0 ? (
                  configuracao?.escola?.items?.map((e: any) => (
                    <MenuItem
                      onClick={(_) => {
                        setConfiguracao((antes: any) => {
                          return {
                            ...antes,
                            escolaSelecao: e,
                            pessoaSelecao: {
                              id_keycloak: -1,
                              nome: "Nenhuma pessoa",
                            },
                            pessoa: {
                              items: [],
                            },
                          };
                        });

                        setConfiguracaoGlobal((antes: any) => {
                          return {
                            ...antes,
                            escolaSelecao: e,
                            pessoaSelecao: {
                              id_keycloak: -1,
                              nome: "Nenhuma pessoa",
                            },
                            pessoa: {
                              items: [],
                            },
                          };
                        });
                      }}
                      key={e.id}
                      value={e.id}
                    >
                      {e.nome}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={-1} disabled>
                    Nenhuma escola
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={11} style={{ marginTop: "1rem" }}>
            {configuracaoGlobal?.selecao === "professor" ? (
              <FormControl fullWidth size="small">
                <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                  Professor
                </InputLabel>

                <Select
                  id="professor-filtro"
                  value={configuracao?.pessoaSelecao?.id_keycloak}
                  label="Professor"
                  name="professor"
                  style={{ color: theme.cor.cor_preto_500 }}
                  disabled={
                    configuracao?.cidadeSelecao?.id === -1 ||
                    configuracao?.escolaSelecao?.id === -1
                      ? true
                      : false
                  }
                >
                  {configuracao?.pessoa?.items?.length > 0 ? (
                    configuracao?.pessoa?.items?.map((e: any) => (
                      <MenuItem
                        onClick={(_) => {
                          setConfiguracao((antes: any) => {
                            return {
                              ...antes,
                              pessoaSelecao: e,
                            };
                          });
                          setConfiguracaoGlobal((antes: any) => {
                            return {
                              ...antes,
                              pessoaSelecao: e,
                            };
                          });
                        }}
                        key={e?.id_keycloak}
                        value={e?.id_keycloak}
                      >
                        {e?.nome}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={-1} disabled>
                      Nenhuma pessoa
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            ) : (
              <FormControl fullWidth size="small">
                <InputLabel style={{ color: theme.cor.cor_preto_500 }}>
                  Estudante
                </InputLabel>

                <Select
                  id="estudante-filtro"
                  value={configuracao?.pessoaSelecao?.id_keycloak}
                  label="Estudante"
                  name="estudante"
                  style={{ color: theme.cor.cor_preto_500 }}
                  disabled={
                    configuracao?.cidadeSelecao?.id === -1 ||
                    configuracao?.escolaSelecao?.id === -1
                      ? true
                      : false
                  }
                >
                  {configuracao?.pessoa?.items?.length > 0 ? (
                    configuracao?.pessoa?.items?.map((e: any) => (
                      <MenuItem
                        onClick={(_) => {
                          setConfiguracao((antes: any) => {
                            return {
                              ...antes,
                              pessoaSelecao: e,
                            };
                          });
                          setConfiguracaoGlobal((antes: any) => {
                            return {
                              ...antes,
                              pessoaSelecao: e,
                            };
                          });
                        }}
                        key={e?.id_keycloak}
                        value={e?.id_keycloak}
                      >
                        {e?.nome}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={-1} disabled>
                      Nenhuma pessoa
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} paddingLeft={"3rem"} paddingTop={"2rem"}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                endIcon={
                  <FilterAltIcon style={{ color: theme.cor.cor_branco_100 }} />
                }
                style={{
                  background: theme.cor.cor_principal_500,
                  fontFamily: theme.fonte.font.fonte_1,
                  color: theme.cor.cor_branco_100,
                }}
                loading={configuracao?.enviar}
                loadingPosition="end"
                onClick={() => {
                  buscar();
                }}
              >
                Filtrar
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                endIcon={
                  <CloseIcon style={{ color: theme.cor.cor_branco_100 }} />
                }
                style={{
                  background: theme.cor.cor_erro_500,
                  fontFamily: theme.fonte.font.fonte_1,
                  color: theme.cor.cor_branco_100,
                }}
                onClick={() => limpar()}
                loading={configuracao?.enviar}
                loadingPosition="end"
              >
                Limpar
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Filtro;
