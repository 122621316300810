import {Grid, Paper} from "@mui/material";
import {useEffect, useState} from "react";
import {useTheme} from "styled-components";
import HugoPesquisa from "../../img/Hugo_analise (1) 1.png";
import CardMenu, {MenuPhone} from "../menu";
import MenuSuperior from "../menuSuperior";
import {mobile} from "../theme";
import {getComprarContrato, getGestor, getNomeContrato} from "../../types";

const Metricas = ({ decodedToken }: any) => {
  const theme = useTheme();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={4}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto", width: "100%" }}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid
            item
            xs={12}
            style={{
              background: theme.cor.cor_branco_100,
              padding: 0,
              margin: 0,
            }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Métricas"} />
          </Grid>
          <Grid
            item
            xs={12}
            padding={3}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid style={{ marginBottom: "-1.5rem", marginLeft: "1.5rem" }}>
              <p
                style={{
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                  padding: "1rem",
                  paddingLeft: "3rem",
                  paddingRight: "3rem",
                  background: "#F3069F",
                  fontWeight: theme.fonte.bold.semi_bold,
                  color: "white",
                  fontFamily: theme.fonte.font.fonte_1,
                  width: "fit-content",
                }}
              >
                {getNomeContrato(decodedToken) ?? "Metrícas"}
              </p>
            </Grid>

            <Paper
              elevation={4}
              style={{ height: "100%", borderRadius: "1rem" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                spacing={2}
                padding={"2rem"}
                style={{ height: "100%" }}
              >
                {getComprarContrato(decodedToken, "govtech") &&
                getGestor(decodedToken) ? (
                  <>
                    <Grid item xs>
                      <iframe
                        title="v1Dashsappens"
                        style={{
                          aspectRatio: "16 / 9",
                          width: "100%",
                          height: "auto",
                          border: 0,
                          borderRadius: "1rem",
                        }}
                        src="https://app.powerbi.com/view?r=eyJrIjoiYWQ3ZTg2OTctYmEzZS00ODAxLTlhNGUtMTNhZjQzYmM1ZTU5IiwidCI6IjY2NmUzMmY3LTNmYWMtNDMxYy1hMjYzLTVjNzk0YmIyNGI1NSJ9"
                      />
                    </Grid>{" "}
                  </>
                ) : (
                  <>
                    {getComprarContrato(decodedToken, "ips") ? (
                      ""
                    ) : (
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                          style={{ height: "100%" }}
                        >
                          <Grid item>
                            <img
                              style={{ marginBottom: "-.2rem" }}
                              src={HugoPesquisa}
                              alt="Hugo Pesquisando"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={true}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ height: "100%" }}
                      >
                        <Grid item xs={11}>
                          <Grid item xs={12}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.semi_bold,
                                color: theme.cor.cor_preto_300,
                                fontFamily: theme.fonte.font.fonte_1,
                              }}
                            >
                              Bem-vindo(a) ao painel de métricas!
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p
                              style={{
                                textAlign: "justify",
                                color: theme.cor.cor_preto_300,
                                fontFamily: theme.fonte.font.fonte_1,
                              }}
                            >
                              Aqui, você pode acompanhar os resultados dos seus
                              estudantes em tempo real, conferir a lista de
                              presença e receber apoio pedagógico do nosso
                              assistente virtual.
                            </p>
                          </Grid>
                          <Grid item xs={12}></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Metricas;
