import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Grid, Paper} from "@mui/material";
import {useEffect, useState} from "react";
import {useTheme} from "styled-components";
import PessoasSorindo from "../../img/Group 149.png";
import CardMenu, {MenuPhone} from "../menu";
import MenuSuperior from "../menuSuperior";
import {mobile} from "../theme";

const TikTokIcon = ({ color = "" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100%"
      height="100%"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const Informacoes = ({ decodedToken }: any) => {
  const theme = useTheme();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={7}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />

          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            style={{ minHeight: "100%" }}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              item
              xs={12}
              style={{ background: theme.cor.cor_branco_100, width: "100%" }}
            >
              <MenuSuperior
                ativo={mobile(windowSize) ? true : false}
                texto={"Informações"}
              />
            </Grid>

            <Grid item padding={2}>
              <Paper
                elevation={4}
                style={{ height: "100%" }}
                sx={{
                  borderRadius: "1rem",
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  minHeight={"100%"}
                  height={"100%"}
                  style={{
                    background: theme.cor.cor_branco_100,
                    borderRadius: "1rem",
                  }}
                >
                  <Grid item xs={true} padding={"2rem"} paddingTop={"1rem"}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12}>
                            <p
                              style={{
                                color: theme.cor.cor_principal_500,
                                marginBottom: 0,
                                fontSize: "20px",
                                fontFamily: theme.fonte.font.fonte_1,
                                overflowWrap: "break-word",
                              }}
                            >
                              Plataforma de Educação
                            </p>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                color: theme.cor.cor_preto_500,
                                fontSize: "60px",
                                fontWeight: 800,
                                fontFamily: theme.fonte.font.fonte_1,
                                overflowWrap: "break-word",
                              }}
                            >
                              Conheça a Sappens
                            </span>
                          </Grid>
                          <Grid item>
                            <p
                              style={{
                                marginTop: "1rem",
                                marginBottom: 0,
                                color: theme.cor.cor_preto_500,
                                fontFamily: theme.fonte.font.fonte_1,
                                textAlign: "justify",
                                overflowWrap: "break-word",
                              }}
                            >
                              A Sappens é uma empresa de tecnologia
                              especializada em{" "}
                              <span
                                style={{
                                  color: theme.cor.cor_principal_500,
                                  fontWeight: "bold",
                                }}
                              >
                                gamificação
                              </span>{" "}
                              para escolas. Nossa missão é transformar a forma
                              como os estudantes aprendem, tornando o processo
                              educativo mais engajador, lúdico e efetivo.
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-end"
                          style={{ height: "100%" }}
                        >
                          <Grid
                            item
                            style={{
                              height: "350px",
                              minWidth: 150,
                              width: "100%",
                              marginBottom: "-2.8rem",
                            }}
                          >
                            <div
                              style={{ position: "relative", height: "100%" }}
                            >
                              <img
                                style={{
                                  height: "auto",
                                  width: "100%",
                                  maxWidth: "350px",
                                  position: "absolute",
                                  objectFit: "scale-down",
                                  bottom: "0",
                                }}
                                src={PessoasSorindo}
                                alt="Pessoas Sorrindo"
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={true}
                    width={"100%"}
                    style={{
                      background: theme.cor.cor_principal_500,
                      padding: "1rem",
                      borderRadius: ".7rem",
                      paddingLeft: "2rem",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <FacebookIcon
                          style={{ color: theme.cor.cor_branco_100 }}
                        />
                      </Grid>
                      <Grid item>
                        <InstagramIcon
                          style={{ color: theme.cor.cor_branco_100 }}
                        />
                      </Grid>
                      <Grid item>
                        <LinkedInIcon
                          style={{ color: theme.cor.cor_branco_100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          height: "30.5px",
                          width: "30.5px",
                          marginBottom: ".2rem",
                          marginLeft: ".1rem",
                        }}
                      >
                        <TikTokIcon color={theme.cor.cor_branco_100} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item style={{ minWidth: "100%" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={2}
                    marginBottom={"1.5rem"}
                  >
                    <Grid item>
                      <span
                        style={{
                          borderBottom: "1px solid black",
                          fontFamily: theme.fonte.font.fonte_1,
                          fontSize: "14px",
                        }}
                      >
                        Termo de Serviço
                      </span>
                    </Grid>
                    <Grid item style={{ paddingRight: "2rem" }}>
                      <span
                        style={{
                          borderBottom: "1px solid black",
                          fontFamily: theme.fonte.font.fonte_1,
                          fontSize: "14px",
                        }}
                      >
                        Política de Privacidade
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Informacoes;
