import {Grid} from "@mui/material";
import Pagination from "@mui/material/Pagination";

function Paginacao({ item, handlePage }: any) {
  return (
    <>
      <div style={{ marginBottom: "1.5rem" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
          spacing={1}
        >
          <Grid item>
            {item && item?.total > item?.size ? (
              <Pagination
                onChange={(e, value) => handlePage(value)}
                style={{ marginTop: "1rem" }}
                count={Math.ceil(item?.total / item?.size)}
                defaultPage={1}
                page={item?.page}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Paginacao;
